@import url(reset.css);
@import url(fonts.css);

html,
body {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
    color: #3d3d3d;
    overflow: hidden;
}

.ft-600 {
    font-weight: 600;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mrg-cen {
    margin: 0 auto;
}

/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

em {
    font-style: normal;
}

.whole-container p,
.whole-container h3,
.whole-container h4 {
    margin: 0;
}

.flex-prop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-cont-inr,
.log-address-inr {
    width: 100%;
    height: 100vh;
}

.log-address-inr {
    width: 100%;
    background: #b9bcc6;
    justify-content: center;
}

.login-cont-right,
.login-cont-left {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.login-cont-left {
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.login-cont-right {
    height: calc(100vh);
    overflow: hidden;
    overflow-y: auto;
}

.log-left-inr {
    background: #f9fafd;
    width: 100%;
    height: 100%;
    text-align: center;
}

.login-cont-right .login-cont-inner {
    width: 100%;
    height: 100%;
}

.log-left-inr h3 {
    letter-spacing: -0.48px;
    font-size: 26px;
    color: #4d6ee3;
}

.log-left-inr span {
    font-size: 14px;
    color: #131d58;
}

.sign-inp h1 {
    max-width: 417px;
    width: 100%;
    margin-bottom: 30px;
    font-size: 32px;
}

.sign-inp .num-cont button {
    position: relative;
    width: 90px;
    border: 1px solid #d9dce6;
    background: #fff;
    padding: 14px;
    border-radius: 8px;
    text-align: left;
    margin-top: 0;
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.sign-inp .num-cont button:after {
    content: "";
    position: absolute;
    content: "";
    right: 0.8rem;
    top: calc(55% - 0.2rem);
    transform: translateY(-50%) rotate(45deg);
    width: 0.4rem;
    height: 0.4rem;
    border-right: 0.12rem solid #3d3d3d;
    border-bottom: 0.12rem solid #3d3d3d;
    transition: 0.2s;
}

.sign-inp .num-cont button.active::after {
    transform: translateY(0%) rotate(225deg);
}

.num-box {}

.sign-inp .num-cont img {
    padding: 0 10px;
}

.sign-inp .arrow {
    position: relative;
    top: -3px;
}

.next-arrow {
    position: relative;
    display: inline-block;
    transform: rotate(45deg);
    top: 0;
    right: -5px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
}

.sign-form .forms-fields-text {
    max-width: 440px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.sign-form .forms-fields-text input {
    margin-top: 10px;
    width: 100%;
}

.whole-container .forms-fields-text input {
    padding: 16px 0 16px 15px;
    border: 1px solid #d9dce6;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 0;
}

.whole-container input::placeholder {
    color: #a9afc3;
}

.num-cont p {
    width: calc(100% - 100px);
}

.label-container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
    margin-right: 10px;
}

.tick-ele {
    float: left;
    display: block;
    margin: 0 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: 1px solid #3d3d3d;
    position: relative;
}

.circle-ele {
    float: left;
    display: block;
    margin: 0 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #e8eaf2;
    position: relative;
}

.sp-selected .label-containerinput:checked~.circle-ele {
    background-color: #fff;
    border: 1px solid;
}

.label-containerinput:checked~.tick-ele {
    background-color: #fff;
    border: 1px solid;
}

.tick-ele:after,
.circle-ele:after {
    content: "";
    position: absolute;
    display: none;
}

.label-container input:checked~.tick-ele:after,
.sp-selected .label-container input:checked~.circle-ele:after {
    display: block;
}

.label-container input:checked~.tick-ele {
    background: #4d6ee3;
    border-color: #4d6ee3;
}

.label-container input:checked~.circle-ele {
    background-color: #fff;
}

.label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;
}

.label-container .circle-ele:after {
    content: "";
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4d6ee3;
}

.tick-ele:after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 7px;
    border: solid #fff;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(44deg);
    position: absolute;
    top: 3px;
    left: 5px;
    display: none;
}

.whole-container span,
.sign-form div label {
    font-size: 13px;
}

.sign-form div label {
    margin-bottom: 12px;
    display: block;
}

.whole-container span button {
    color: #4d6ee3;
}

.verify-btn {
    background: #4d6ee3;
    max-width: 440px;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 20px 0;
    color: #fff;
    margin-top: 15px;
    font-weight: 800;
    margin-bottom: 25px;
    cursor: pointer;
}

.login-otp-inr .login-cont-right {
    text-align: center;
}

.otp-right-inr p {
    color: #373943;
    font-size: 16px;
    font-weight: 600;
    margin: 9px 0 5px;
}

.opt-boxes {
    margin-top: 40px;
    max-width: 336px;
    width: 100%;
    margin: 38px 0px 20px 0px;
}

.whole-container .opt-boxes input {
    width: 60px !important;
    height: 60px !important;
    padding: 0;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    border: 1px solid #d9dce6;
    border-radius: 12px;
}

.otp-right-inr {
    width: 100%;
}

.log-address-cont {
    max-width: 801px;
    width: 100%;
    background: #fff;
    position: relative;
    border-radius: 10px;
}

.log-address-top {
    padding: 25px;
}

.address-top1 {
    max-width: 165px;
    width: 100%;
}

.scl-name {
    font-size: 20px;
    color: #181558;
    font-weight: 800;
}

.log-address-top p {
    font-size: 18px;
    font-weight: 600;
    color: #373943;
}

.log-address-cont h3 {
    font-size: 20px;
    text-align: center;
    margin: 10px 0 40px;
    font-weight: 700;
    color: #000000;
}

.box-container {
    min-height: 300px;
}

.log-address-inr input {
    width: 100%;
    display: block;
    margin: 40px auto 200px;
}

.log-address-inr .rang-bar {
    display: block;
    width: 100%;
    height: 2px;
    background: #d9dce6;
    position: absolute;
    left: 0;
}

.progress-bar {
    width: 100%;
    background-color: #d9dce6;
    height: 2px;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar-fill {
    background-color: #4d6ee3;
    height: 100%;
    transition: width 0.3s ease-in-out;
    width: 20%;
}

.next-btn,
.back-btn {
    width: 84px;
    padding: 10px 0;
    background: #4d6ee3;
    border: 0;
    color: #fff;
    font-weight: 600;
    position: relative;
    float: right;
    margin-top: 30px;
    border-radius: 6px;
    cursor: pointer;
}

.next-btn:after {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    left: 8px;
    top: 0;
    display: inline-block;
    position: relative;
    transition: all 0.4s ease;
}

.next-btn:not([disabled]):hover:after {
    left: 10px;
}

.back-btn:not([disabled]):hover:before {
    right: 6px;
}

.back-btn {
    float: left;
    background: #fff;
    color: #000;
    border: 1px solid #d7d9dd;
    border-radius: 6px;
    font-weight: 500;
}

.back-btn:before {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgb(255, 255, 255);
    transform: rotate(-132deg);
    font-weight: 500;
    margin-right: 10px;
    top: -1px;
    display: inline-block;
    position: relative;
    transition: all 0.4s ease;
    right: 0;
}

.back-btn .next-arrow {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(-135deg);
    left: -5px;
    top: -1px;
}

.scl-size-cont button,
.scl-prg button {
    width: auto;
    height: 34px;
    background: #fff;
    border: 1px solid #d9dce6;
    border-radius: 8px;
    cursor: pointer;
    color: #373943;
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
}

.scl-size-cont button.sp-selected,
.scl-prg button.sp-selected {
    border: 1px solid #4d6ee3;
    border-radius: 8px;
    color: #4d6ee3;
    box-shadow: 0px 2px 5px 0px #4d6ee338;
    background-color: #4d6ee3;
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;

}

.scl-prg button .tick-ele:after {
    border-color: #4d6ee3;
    top: 4px;
    left: 6px;
}

.scl-prg button .tick-ele:after {}

.scl-prg button .tick-ele {
    border-color: #d9dce6;
}

.scl-prg button .label-container input:checked~.tick-ele {
    background-color: #fff;
}

.size-cont2,
.size-cont1 {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
}

.size-cont1 {
    max-width: 450px;
    margin-bottom: 20px;
}

/* .scl-size-cont {
    margin: 40px 0 150px;
} */

.program-size button {
    width: 114px;
}

.program-size {
    padding-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
}

.select-box .options.active {
    display: block;
}

.select-box ol li.hide {
    display: none;
}

.options {
    position: absolute;
    top: 4.7rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
    width: 100%;
    border: 1px solid #d9dce6;
    z-index: 2;
}

.options ol {
    list-style: none;
    max-height: 12rem;
    overflow: overlay;
    padding: 0;
}

.select-box ol li:not(:last-child) {
    border-bottom: 0.1rem solid #eee;
}

.select-box ol li {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.select-box ol li:hover {
    background-color: #f4f5f9;
}

.select-box ol li.option div {
    display: flex;
    gap: 5px;
}

.select-box ol li.option em {
    font-size: 13px;
}

.search-box {
    margin-top: 0;
}

.terms-cont {
    display: flex;
    align-items: center;
}

button:disabled,
button[disabled] {
    opacity: 0.6;
    cursor: default;
}

img {
    max-width: 100%;
}

.sp-search-school {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
}

.search-list {
    position: absolute;
    top: 55px;
    border: 1px solid #d9dce6;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    max-width: 630px;
    left: 0;
    max-height: 16rem;
    overflow: overlay;
    background-color: #fff;
    z-index: 2;
}

.search-li img,
.sch-text-op img {
    width: 36px;
}

.dis-block {
    display: block;
}

.search-li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 6px;
    border-radius: 6px;
    cursor: pointer;
}

.search-li:hover {
    background-color: #f4f5f9;
}

.search-li .title-text {
    color: #373943;
    font-size: 13px;
}

.search-li .title-text em {
    margin-bottom: 5px;
}

.search-sch-list {
    border: 1px solid #d9dce6;
    padding: 18px 15px;
    border-radius: 6px;
}

.sch-text-op {
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    left: 11px;
    background-color: #fff;
    font-size: 13px;
    gap: 10px;
    width: 86%;
}

.last_button {
    display: none;
    width: 84px;
    padding: 10px 0;
    background: #4d6ee3;
    border: 0;
    color: #fff;
    font-weight: 600;
    position: relative;
    float: right;
    margin-top: 30px;
    border-radius: 6px;
    cursor: pointer;
}

#schoolList {
    display: none;
}

#schoolList.active {
    display: block;
}

.close-search {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 20px;
    height: 20px;
    font-size: 0px;
    border-radius: 50%;
    background-color: #f3f3f4;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.close-search:after {
    content: "";
    background-size: 100%;
    width: 8px;
    height: 8px;
    display: block;
}

.pos {
    position: relative;
}

.close-search.active {
    display: flex;
}

.sp-progress-btn {
    padding: 0 25px 25px;
}

.box-container>div.sp-sec-active {
    display: block;
}

.box-container>div {
    display: none;
}

.whole-container span .resent_otp {
    color: #4d6ee3;
    cursor: pointer;
    font-size: 13px;
}

.outer-container {
    overflow: hidden;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
}

.overlay.visible {
    opacity: 1;
    visibility: visible;
}

.sp-left-panel {
    width: 17.19em;
    position: fixed;
    left: 0;
    height: 100%;
    border: 1px solid var(--sp-border-clr);
    background-color: #fff;
}

.sp-top-band {
    padding: 15px 15px 0;
}

.sp-logo sup {
    color: var(--sp-clr-red);
    text-transform: uppercase;
    margin-left: 10px;
    font-weight: 700;
    font-size: 0.625em;
    vertical-align: text-top;
}

.sp-search-top {
    background-color: var(--sp-search-clr);
    width: 100%;
    padding: 10px;
    margin-top: 16px;
    border-radius: 6px;
}

.flexc {
    display: flex;
    align-items: center;
}

.sp-middle-band {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 58px);
    display: flex;
    flex-direction: column;
}

.sp-nav {
    padding: 0 15px 15px 18px;
}

.sp-nav li {
    padding: 12px 0;
    font-size: 0.875em;
    border: 1px solid transparent;
    position: relative;
    line-height: 1;
}

.sp-nav li:before {
    content: "";
    width: calc(100% + 9px);
    background-color: transparent;
    height: 100%;
    position: absolute;
    left: -6px;
    border: 1px solid transparent;
    z-index: -1;
    top: 0;
    border-radius: 6px;
}

.sp-nav li:hover:before,
.sp-nav li.selected:before {
    background-color: #eff2ff;
    border-color: var(--sp-hg-clr);
    box-shadow: 0px 1px 3px 0px #bec4e033;
}

.sp-nav li:hover,
.sp-nav li.selected {
    color: var(--sp-blue-clr);
}

.sp-nav li:hover [class^="icon-"]:before,
.sp-nav li.selected [class^="icon-"]:before {
    color: var(--sp-blue-clr);
}

.sp-nav li span {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.y-auto {
    overflow-y: auto;
}

.sp-title {
    font-size: 0.815em;
    color: var(--sp-ft-clr);
    font-weight: 600;
    /* letter-spacing: 0.5px; */
    margin: 1.25em 0;
    line-height: 1;
}

.justifySp {
    justify-content: space-between;
}

.sp-plus {
    width: 18px;
    height: 18px;
    border: 1px solid var(--sp-blue-clr);
    border-radius: 3px;
    color: var(--sp-blue-clr);
    text-align: center;
    cursor: pointer;
    line-height: 14px;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--sp-hg-clr);
}

.sp-menubar-clslist .sp-plus {
    background-color: transparent;
    border-color: var(--sp-br-clr);
    color: var(--sp-normal-clr);
    width: 22px;
    height: 22px;
    line-height: 1;
}

.sp-bottom {
    border-bottom: 1px solid var(--sp-divider-clr);
    width: 100%;
    left: 0;
    padding: 15px;
    /* margin-bottom: 60px; */
    background-color: #fff;
}

.sp-bottom-sticky {
    padding: 20px;
    box-shadow: 0px 0px 14px 0px #0000001a;
}

.sp-right-panel {
    width: calc(100% - 16em);
    margin-left: 17.159em;
    position: relative;
    /* max-width: 1600px; */
}

.sp-top-bar {
    padding: 1.25em 0.625em;
}

.selected-icon {
    /* width: 28px;
    height: 28px; */
    border: 1px solid var(--sp-border-secondary-clr);
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.selected-icon i::before {
    margin-right: 0;
}

.sp-top-box .sp-view-btn {
    padding: 10px 19px;
}

.createN {
    background-color: var(--sp-blue-clr);
    color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    font-family: inherit;
    font-size: 0.8125em;
}

.sp-header {
    margin-top: 10px;
    /* padding-left: 30px; */
}

.sp-header nav {
    border-bottom: 1px solid var(--sp-border-clr);
}

.sp-list-view.selected {
    font-weight: 500;
    color: #4d6ee3;

}

.sp-list-view.selected i:before {
    color: #4d6ee3;
}

.sp-list-view i:before {
    height: 17px;
}

.sp-list-view {
    font-size: 13px;
    color: var(--sp-secondary2-clr);
    margin-left: 10px;
    padding: 0 10px 10px;
    /* border-bottom: 2px solid transparent; */
    cursor: pointer;
    line-height: 1;
}

.sp-menu-bar {
    padding: 0.75em 20px 10px;
}

.sp-menugroup>div:first-child {
    gap: 10px;
    /* flex: 1; */
}

.btn-dropdown-fieldval .icon-Tick:before {
    color: #3d3d3d;
    opacity: 0;
}

.btn-dropdown-fieldval {
    width: 200px;
    border: 1px solid var(--sp-border-clr);
    border-radius: 6px;
    padding: 8px 13px;
    box-shadow: 0px 6px 14px 0px #0000001a;
    z-index: 2;
    background-color: #fff;
}

.btn-dropdown-fieldval li {
    font-size: 0.8125em;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}

.btn-dropdown-fieldval li.selected .icon-Tick:before {
    opacity: 1;
}

.btn-dropdown-fieldval li:hover,
.btn-dropdown-fieldval li.selected {
    background-color: #f1f3f9;
}

.btn-dropdown {
    font-size: 0.8125em;
    font-weight: 400;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--sp-border-menu);
}

.btn-dropdown .icon-Down-Arrow:before {
    font-size: 0.875em;
    margin-right: 0;
    margin-left: 5px;
}

.field-label .btn-dropdown .icon-Down-Arrow:before {
    font-size: 0.8125em;
}

.btn-search-bar {
    padding: 8px 6px;
    border-radius: 8px;
    border: 1px solid var(--sp-border-menu);
    max-width: 300px;
}

.btn-search-bar input {
    min-width: 248px;
    font-size: 0.8125em;
    padding: 0;
    line-height: 1.5rem;
}

.sp-msg-sec-right .btn-search-bar {
    max-width: 904px;
    width: 100%;
    position: relative;
    margin-left: 10px;
}

.sp-msg-sec-left .btn-search-bar {
    margin: 20px 30px 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.sp-menubar-clslist li {
    /* margin-left: 10px; */
    /* width: 22px;
    height: 22px; */
}

.load-container.clr-grey {
    width: 100%;
    background-color: #f9f9f9;
}

.sp-table-container {
    overflow: hidden;
    min-width: 1200px;
    max-width: 1600px;
}

.sp-table-container .checkbox {
    flex: 0 0 2%;
    cursor: pointer;
}

.sp-table-header {
    border-bottom: 1px solid var(--sp-border-menu);
    padding: 0 20px;
}

.checkbox i {
    width: 18px;
    height: 18px;
    border: 1px solid var(--sp-border-menu);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox i.checked {
    background-color: var(--sp-theme-color2);
    border-color: var(--sp-theme-color2);
    text-align: center;
}

.checkbox i.checked:before {
    font-size: 0.675em;
    color: #fff;
    line-height: 1.6;
}

.sp-table-body .row {
    padding: 0px 20px;
    border-bottom: 1px solid var(--sp-border-clr);
    height: 44px;
}

.sp-table-body .row {
    /* background-color: #F4F6FF; */
}

.sp-table-body {
    /* height: calc(100vh - 158px); */
    overflow: hidden;
    overflow-y: auto !important;
    /* height: 680px !important; */
}

.sp-td-content {
    font-size: 0.8125em;
    color: var(--sp-tb-color);
    white-space: nowrap;
}

.text-ellp {
    overflow: hidden;
    white-space: nowrap;
    /* width: 90%; */
    display: block;
    text-overflow: ellipsis;
}

.sp-tag-selected {
    background-color: var(--sp-tag-color);
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
    /* font-size: 0.625em; */
    margin: 2px;
    /* white-space: nowrap; */
}

/* .sp-tag-selected.food_date {
    background-color: var(--sp-tag-color);
    padding: 5px 3px;
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    margin: 1px;
} */

/* .sp-tag-selected.food_date {
    background-color: var(--sp-tag-color);
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    margin: 1px;
    padding: 5px 3px;
} */

.sp-tag-selected.food_date {
    background-color: var(--sp-tag-color);
    border-radius: 4px;
    display: inline-block;
    font-size: 10px;
    margin: 2px;
    padding: 5px 5px;
}

.popup-modal-container {
    position: absolute;
    width: 50%;
    right: 0;
    background-color: #fff;
    height: 100%;
    z-index: 2;
    top: 0;
    max-width: 790px;
    transform: translateX(100%);
    transition: all 0.6s ease;
}

.popup-modal-container.visible,
.popup-student-details.visible {
    transform: translateX(0%);
}

.popup-modal-container h2 {
    font-size: 1.25em;
    padding: 20px;
}

.popup-modal-program {
    position: absolute;
    width: 50%;
    right: 0;
    background-color: #fff;
    height: 100%;
    z-index: 2;
    top: 0;
    max-width: 790px;
    transform: translateX(100%);
    transition: all 0.6s ease;
}

.popup-modal-program.visible,
.popup-student-details.visible {
    transform: translateX(0%);
}

.popup-modal-program h2 {
    font-size: 1.25em;
    padding: 20px;
}

.sp-pop-header {
    padding: 0px 20px;
    height: calc(100vh - 138px);
    overflow: scroll;
}

.field-label {
    margin-top: 20px;
}

.field-label label {
    font-weight: 500;
    font-size: 0.8125em;
}

.dialog-model .field-label {
    text-align: left;
}

.field-label-op .cross-ele,
.field-label-op .notapp-ele,
.field-label-op .icon-Tick {
    transform: scale(0.8);
    margin-right: 10px;
}

.field-label-op .icon-Tick {
    text-align: center;
}

.field-value-holder {
    width: 100%;
    border: 1px solid var(--sp-border-menu);
    box-shadow: 0px 2px 5px 0px #0000000d;
    position: relative;
    border-radius: 8px;
}

.field-value-holder .icon-Timetable-Outline {
    right: 0;
}

.sp-field-select,
.sp-field-input {
    width: 100%;
    display: block;
    border: none;
    font-size: 0.8125em;
}

.sp-field-input {
    /* padding: 12px; */
    padding: 9px 12px;
    border-radius: 8px;
}

.dialog-model .field-label {
    text-align: left;
}

.half-field {
    width: 47%;
}

.sp-upload-btn {
    font-size: 0.8125em;
    width: 100%;
    padding-left: 10px;
}

.sp-file-browse {
    width: 100px;
    padding: 15px 10px;
    background-color: #f9f9fa;
    border-radius: 6px;
    border-left: 1px solid var(--sp-border-menu);
    color: #1b252f;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
}

.popup-student-details {
    max-width: 450px;
    width: 60%;
    border: 1px solid var(--sp-border-clr);
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 23;
    bottom: 0;
    box-shadow: 0px 5px 14px 0px #0000000d;
    transform: translateX(100%);
    transition: all 0.8s ease;
}

.popup-student-details:before {
    content: "";
    position: absolute;
    top: 0;
    background: linear-gradient(180deg,
            #fae8d6 0%,
            rgba(255, 226, 245, 0.8) 31.23%,
            #f4f0ff 70.82%,
            #ffffff 100%),
        radial-gradient(50% 74.51% at 50% 100%,
            rgba(255, 255, 255, 0.5) 24.09%,
            rgba(255, 255, 255, 0.16) 100%);
    width: 100%;
    height: 100px;
}

.close {
    border: 1px solid var(--sp-secondary-clr3);
    width: 34px;
    height: 34px;
    justify-content: center;
    z-index: 22;
    position: relative;
    background-color: #fff;
    position: absolute;
    right: 10px;
    top: 20px;
    border-radius: 6px;
    color: #3d3d3d;
    font-size: 15px;
}

.cp {
    cursor: pointer;
}

.student-details-page {
    padding: 60px 20px 40px;
    position: relative;
    height: calc(100vh - 20px);
    overflow: hidden;
    overflow-y: auto;
}

.sp-top-details {
    align-items: self-end;
    justify-content: space-between;
}

.sp-profile-head img {
    width: 62px;
}

.sp-status {
    background-color: var(--sp-theme-clr3);
    color: var(--sp-status-clr);
    font-size: 0.6875em;
    padding: 4px 12px;
    border-radius: 8px;
    margin-left: 20px;
    text-transform: uppercase;
}

.sp-accbox {
    width: 34px;
    height: 34px;
    background-color: #ebeffa;
    border: 1px solid #e2e8f9;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
}

.sp-accbox::before {
    color: var(--sp-blue-clr);
}

.sp-details-box {
    border: 1px solid #e1e4ea;
    border-radius: 14px;
    padding: 20px 15px;
    margin-top: 30px;
}

.sp-details-box .sp-dashboard {
    text-align: center;
}

.field-label .label-title {
    color: #686c7b;
    font-weight: 300;

    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.label-filled {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #000000;
    padding: 10px 0px;
}

.sp-dashboard {
    text-align: center;
}

.sp-dashboard img {
    width: 200px;
    display: inline-block;
}

.dash-row,
.dashboard-top,
.dashboard-cont-right .sp-dashboard {
    margin-bottom: 20px;
    gap: 10px;
}

.dashboard-cont-right .sp-dashboard {
    max-width: 400px;
    width: 100%;
}

.sp-dashboard-container {
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
}

.sp-activ-bt1 .sp-lpdr {
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid #fff;
    background: none;
}

.sp-activ-bt1 .sp-lpdr::before {
    font-size: 0.625em;
    line-height: 13px;
}

.sp-lpdr {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.sp-lpdr {
    align-items: center;
    background-color: var(--sp-green-clr);
    border-radius: 50%;
    display: inline-flex;
    height: 16px;
    justify-content: center;
    width: 16px;
}

.sp-lpdr {
    background-color: var(--sp-green-clr);
}

.label-prf-img img {
    width: 50px;
}

[class^="icon-"]:before {
    font-size: 1.1em;
    margin-right: 8px;
}

.sp-table-body .row img,
.students-add-list ul li img {
    width: 22px;
}

.icon-Tick:before {
    font-size: 0.925em;
    color: #fff;
    line-height: 20px;
}

.main_panda {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.student_input [type="text"],
[type="email"],
[type="password"],
[type="url"],
[type="date"],
textarea {
    line-height: 2;
}

.sp-table-header .sp-table-body .sp-tag-selected {
    font-size: 0.925em !important;
}

/* .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
} */

/*  */

.act_input [type="text"],
[type="password"]:focus {
    --tw-ring-color: #f1f1f1;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    /* box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow); */
    border-color: #f1f1f1;
}

/*  */
.acount_type {
    text-align: center;
}

.acount_type span {
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: center;
    color: #373943;
}

.acount_type a {
    color: #325ae8;
}

/* last update */
.verify-btn:hover {
    background: #3e52c1 !important;
}

.createN:hover {
    background: #3e52c1 !important;
}

.sp-btn-create:hover {
    background: #3e52c1 !important;
}

.field-value-holder {
    margin-top: 10px;
}

.createN {
    cursor: pointer;
}

.verify-btn {
    cursor: pointer;
}

.sp-btn-create {
    cursor: pointer;
}

.truncate {
    line-height: 1.5;
}

.but_sign_heg {
    margin-top: 20px !important;
}

.size-cont1.program-size.flex-prop .last_div {
    /* margin-bottom: 20px; */
}

/* @media screen and (max-width: 1440px) and (min-width: 500px) {
    .students-add-list>ul>li:first-child {
        max-width: 185px !important;
    }
} */

/* 10-04 */
.mar_20bott {
    margin-bottom: 20px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-picker-outlined {
    width: 100%;
    padding: 12px;
}

.field-value-holder .divide-gray-100.rounded.shadow {
    width: 202px;
    top: 5px !important;
}

.extra_pad_drop .field-value-holder .divide-gray-100.rounded.shadow {
    width: 220px;
    top: 5px !important;
    height: 16pc;
    overflow: auto;
}

/* 11 */

.custom_size_info {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

[type="search"]:focus {
    --tw-ring-shadow: unset !important;
    border-color: unset !important;
}

/* 12 */
.sp-field-input {
    /* Your existing styles for the input field */
    border: 1px solid #ccc;
    /* Example border color */
}

.error .sp-field-input {
    border: 1px solid red;
    /* Red border color for error state */
}

/* alterdy  */
.error {
    color: red;
    font-size: 12px;
    /* margin-top: 5px; */
}

.success_cuc p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    padding-left: 10px !important;
}

.css-1f8bwsm {
    display: contents !important;
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 0px !important;
}

.error_mes_cus p {
    margin-top: 5px;
}

.sp-top-band a {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.back_overlay {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.yes_button_sta_cus {
    background-color: #f34b4b !important;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
}

.yes_button_sta_cus:hover {
    background-color: #e66262 !important;
}

.can_cus_rec {
    background-color: #ffffff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    color: #1b252f;
}

.can_cus_rec:hover {
    color: #1b252f !important;
}

.h-\[calc\(100\%-1rem\)\] {
    height: calc(100% - 0rem) !important;
}

.save_button_cus {
    background-color: #4d6ee3 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 11.38px;
    text-align: left;
    color: #ffffff;
}

.save_button_cus:hover {
    background-color: #1e429f !important;
}

.can_pro_but {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 11.39px;
    text-align: left;
    color: #1b252f;
}

.drop_move_cus {
    border: 1px solid var(--sp-border-menu);
    border-radius: 8px;
    padding: 1px 0px;
}

.drop_move_cus button {
    padding: 0px !important;
}

.drop_move_cus ul li {
    /* padding: 10px; */
    padding: 0px;
}

.drop_move_cus i {
    padding-right: 5px;
}

.flex.items-stretch.transition-all.duration-200.rounded-md.px-4.py-2.text-sm {
    display: flex;
    align-items: center;
}

.dialog-model {
    left: 50%;
    transform: translate(-50%, 0%);
    top: 30px !important;
}

.eventvalc_cus .dialog-model {
    height: fit-content;
}

/* .eventvalc_cus .dialog-model {
    height: 100vh;
    overflow: scroll;
} */

.create_cus_data {
    overflow: auto;
    /* height: 100vh; */
    left: 50%;
    transform: translate(-50%, 0%);
    top: 10px !important;
}

.rbc-month-view {
    display: block !important;
}

.sp-timetab-inr {
    height: 100vh !important;
}

.rbc-row-bg {
    height: auto !important;
    min-height: 150px !important;
    display: flex;
    flex-direction: unset !important;
}

.rbc-month-row {
    height: auto !important;
}

.rbc-row-content {
    height: auto;
    min-height: 150px !important;
}

.rbc-show-more {
    position: absolute;
    bottom: 10px;
}

.rbc-event-content span strong {
    white-space: normal;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #1b252f;
}

.rbc-event-content span {
    font-size: 11px;
    font-weight: 400 !important;
    line-height: 13.31px;
    text-align: left;
    color: #676a74;
}

.rbc-calendar {
    height: auto !important;
    padding: 0px 0px 50px 0px;
}

.sp-timetab-inr {
    /* padding-bottom: 90px!important; */
}

.rbc-month-view {
    height: auto !important;
}

.rbc-event-content {
    font-size: 13px;
    font-weight: 500;
    color: #1b252f;
    text-transform: capitalize;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    padding: 10px !important;
    background-color: #f2f4f8 !important;
    color: #1b252f !important;
}

.rbc-row-segment {
    padding: 10px 10px !important;
}

.rbc-calendar span {
    text-transform: capitalize !important;
    font-size: 12px;
    font-weight: 500;
    color: #1b252f;
}

.view-rbc-btn-group {
    display: flex !important;
    gap: 0px !important;
    padding: 3px !important;
    background-color: #f2f4f8 !important;
    border-radius: 10px !important;
}

.view-rbc-btn-group button.rbc-active {
    border: none !important;
    background-color: white !important;
    box-shadow: none !important;
}

.view-rbc-btn-group button {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.rbc-btn-group {
    display: flex !important;
    gap: 5px;
}

.rbc-toolbar {
    flex-direction: row-reverse;
    justify-content: space-between !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    /* // 12-09-2024 single line */
}

.rbc-row-segment .rbc-event-content {
    overflow: visible !important;
}

.rbc-toolbar button {
    padding: 10px 15px !important;
    border-radius: 8px !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #1b252f;
}

.rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #d9dce6;
    border-color: #d9dce6;
}

.rbc-toolbar-label {
    display: block !important;
}

.rbc-header {
    padding: 15px 3px !important;
    text-align: end !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #1b252f !important;
}

.head_cus_cal {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #000000;
}

.para_cus_sty label {
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 18.2px;
    text-align: left;
    color: #676a74;
}

.field_cus_cal_sty {
    font-size: 13px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
    text-transform: capitalize;
}

.edit_cus_style {
    width: 415px !important;
}

.all_pro_cus_sty p {
    border-radius: 8px;
    background: #f2f4f8;
    border: 1px solid #f2f4f8;
    padding: 5px 10px;
}

.event_calc_cus_st [type="date"],
[type="password"],
[type="text"]::placeholder {
    font-weight: bolder;
    color: #a9afc3;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
}

.event_calc_cus_st [type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
    color: #373943;
    white-space: nowrap;
    font-weight: 600;
    font-size: 13px !important;
}

.pos_icon_date_st {
    position: relative;
}

.pos_icon_date_st i {
    position: absolute;
    right: 10px;
}

.editorClassName {
    /* border: 1px solid #f1f1f1;  */
    border-radius: 14px;
    min-height: 200px;
    padding: 10px;
    /* margin: 10px 0px;  */
}

.editorHeaderClassName {
    /* border: 1px solid #f1f1f1;
    border-radius: 14px;
    padding: 10px;
    margin: 10px 0px; */
}

.act_program .activity_drop button {
    background: transparent;
    white-space: nowrap;
    padding: 0px;
    /* padding: 5px 15px; */
    color: #000;
}

.act_program .activity_drop button span {
    padding: 0px;
}

.announce-post-left .sp-post-bt {
    padding-right: 20px !important;
}

/* .activity_drop ul li {
    padding: 5px 15px;
} */

.por_div_cus span {
    background: #f2f4f8;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 13px;
}

.sp-btn-cancel:hover {
    background: #f3f3f7;
}

.announce-post-inr {
    margin-top: 10px;
}

.dis_fe_st_pos {
    padding-left: 5px;
    display: flex;
    justify-content: flex-end;
}

.activity-colum-cont {
    padding-left: 10px;
}

.extra_per_div {
    width: 90%;
}

.pad_div_5t {
    padding-top: 60px;
}

[multiple]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
select:focus,
textarea:focus {
    --tw-ring-shadow: unset;
}

/* 06.24 */

.name_bold {
    font-weight: 600;
}

[type="checkbox"],
[type="radio"] {
    color: #4d6ee3 !important;
}

.hover\:bg-gray-100:hover {
    border-radius: 5px;
}

.drop_down_show {
    position: absolute;
    background: white;
    box-shadow: 0px 6px 14px 0px #0000001a;
    width: 202px;
    top: 5px !important;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
        0 1px 2px -1px var(--tw-shadow-color);
    border-radius: 6px;
}

.sp-btn-cancel,
.sp-btn-create {
    font-size: 13px;
}

.drop_move_cus .pad_progr {
    padding: 8px 15px !important;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #1b252f;
}

.drop_move_cus .pad_progr1 {
    padding: 6px 10px !important;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #1b252f;
}

.hei_4px {
    height: 40px;
}

/* ---------------------------------------------- */
/* 
.rbc-off-range-bg, 
.rbc-off-range {
    background: #fff !important;
} */

.rbc-date-cell .rbc-button-link {
    /* margin: 10px; */
}

.rbc-date-cell.rbc-now.rbc-current .rbc-button-link {
    margin-top: 5px;
    padding: 6px 6px;
    /* 11-09-2024 */
    border-radius: 50%;
    background: #4d6ee3;
    color: white;
}

.rbc-time-header-content .rbc-allday-cell {
    display: none !important;
}

.rbc-day-bg.rbc-today {
    background-color: white !important;
}

.rbc-time-column .rbc-timeslot-group {
    min-height: 130px !important;
    /* align-items: center !important; */
}

.rbc-timeslot-group .rbc-time-slot {
    display: flex;
    align-items: end;
}

.rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header {
    display: grid;
    align-items: center !important;
}

.rbc-header {
    background-color: #fafbfc !important;
}

.rbc-today {
    background-color: #ecf1ff !important;
}

.rbc-today span {
    color: #4d6ee3 !important;
}

.rbc-toolbar .btn-search-bar {
    box-shadow: 0px 2px 5px 0px #0000000d;
}

/* Center-align the text in the calendar header for the week view */
.rbc-toolbar {
    display: flex;
    justify-content: center;
    text-align: center;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
}

.rbc-toolbar button {
    margin: 0 5px;
}

.rbc-time-view .rbc-row {
    min-height: 43px !important;
}

.rbc-today span {
    color: #4d6ee3 !important;
}

.rbc-today {
    background-color: #ecf1ff !important;
}

.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-dropdown-toggle::after {
    content: none;
    /* Hides the default arrow */
}

/* //02-07-2024// */

.custom-time-gutter-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.custom-event {
    padding: 0px;
}

.event-title {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #1b252f;
}

.event-dates {
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
    text-align: left;
    color: #676a74;
}

.rbc-day-slot .rbc-event-label {
    display: none;
}

.rbc-events-container {
    width: 100% !important;
}

.rbc-event {
    width: 96% !important;
    margin: 0px 3px 0px 3px !important;
}

.rbc-day-slot .rbc-event {
    left: 0% !important;
}

.rbc-current-time-indicator {
    background-color: #f55151 !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.rbc-toolbar .rbc-toolbar-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
}

.rbc-row.rbc-time-header-cell .rbc-header button span {
    text-transform: uppercase !important;
}

.sp-field-input.date:focus {
    outline: none !important;
    border: 0px solid !important;
    box-shadow: none !important;
}

.rbc-date-cell .rbc-button-link {
    margin: 10px 10px 0px 0px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: #1b252f;
}

.arrow-btn-group button {
    border: 1px solid #d9dce6;
    box-shadow: 0px 2px 5px 0px #0000000d;
}

.tick-ele {
    float: left !important;
    display: block !important;
    margin: 0 0 auto !important;
    width: 18px !important;
    height: 18px !important;
    border-radius: 5px !important;
    border: 1px solid #3d3d3d !important;
    position: relative !important;
}

.label-container input:checked~.tick-ele {
    background: #4d6ee3 !important;
    border-color: #4d6ee3 !important;
}

.rbc-time-header .rbc-label.rbc-time-header-gutter {
    background: #fafbfc !important;
    border-bottom: 2px solid #e8eaf2 !important;
}

.rbc-time-content {
    border-top: 0px solid #e8eaf2 !important;
}

/* Targeting month view table rows */
.rbc-month-view .rbc-row {
    border-color: #e8eaf2;
}

/* -----------------------06-07---------------------- */

/* // sakthivel 03-04-05 JULY 2024 start // */

.disable-data-field img {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
}

.custom button span svg {
    display: none;
}

/* Fixed toolbar */
.rbc-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
}

/* Fixed time header for week view */
.rbc-time-header {
    position: sticky;
    top: 60px;
    /* 12-09-2024 single line */
    z-index: 10;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: -5px !important;
}

/* 12-09-2024 start */
.rbc-toolbar {
    height: 60px !important;
}

/* 12-09-2024 end */
/* Fixed month header */
.rbc-month-view .rbc-row.rbc-month-header {
    position: sticky;
    top: 60px;
    /* 12-09-2024 single line */
    z-index: 10;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: -5px !important;
}

.notify-check-box {
    margin-top: 20px !important;
}

.flexc.notify-check-box input {
    margin: 0 8px auto 0px !important;
    width: 18px !important;
    height: 18px !important;
    border-radius: 5px !important;
    border: 1px solid #3d3d3d !important;
}

.flexc.notify-check-box input:checked {
    border: 1.5px solid #4d6ee3 !important;
}

.flexc.notify-check-box label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
}

.rbc-time-content .rbc-time-slot {
    border-bottom: none;
}

.rbc-day-slot .rbc-time-slot {
    border-top: none !important;
}

.custom div {
    height: 200px !important;
    overflow-y: auto !important;
}

.mt-4 {
    margin-top: 4px !important;
}

/* // sakthivel 03-04-05 JULY 2024 end // */

.mr-17 {
    margin-right: 17px !important;
}

/* .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    background-color: #fff !important;
    border: 1px solid #d9dce6 !important;
    border-radius: 10px !important;
    box-shadow: 0 8px 24px 0 #0000000f !important;
    transform-origin: none !important;
    transition: none !important;
} */


/* 25.07.2024 */
.rbc-day-bg {
    background-color: white !important;
}

.rbc-overlay {
    z-index: 100 !important;
}

.rbc-event-continues-after {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    margin: 0px 0px 22px !important;
}

.rbc-overlay-header {
    margin: -10px -10px 16px -10px !important;
    padding: 10px !important;
    font-family: Inter !important;
}

.activity-row2 {
    position: relative;
    margin-top: 10px;
    overflow: auto;
    max-height: 150px;
}

.activity-row2::-webkit-scrollbar {
    width: 8px;
}

.activity-row2::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.activity-row2::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.activity-row2::before {
    left: 3.3% !important;
    top: 18% !important;
}

.rbc-header {
    padding: 15px 10px !important;
}

.rbc-header span {
    text-transform: uppercase !important;
}

.sp-timetab-inr {
    padding-right: 5px !important;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .sp-dashboard-container {
        width: 98%;
    }

    .dashboard-cont-left {
        min-width: 470px !important;
    }

    .tab-responsive {
        flex-wrap: wrap;
        align-items: center !important;
    }

    .dash-bar-chart,
    .dash-row4-activity {
        min-width: 470px !important;
    }

    .dash-row3-box {
        min-width: 230px !important;
    }

    .dashboard-cont-right .sp-dashboard {
        max-width: 470px !important;
    }
}

.notification_header h4 {
    cursor: pointer;
}

/* -----------26-07---------- */

.event-title {
    font-weight: 600;
    color: #484f5d !important;
}

.event-dates {
    font-weight: 500;
    color: #9398a2 !important;
}

/* 08-10-24 start */
.rbc-event:not(.selected-date),
.rbc-day-slot .rbc-background-event,
.rbc-event:not(.selected-date) {
    border: 2px solid #33bfff !important;
    background-color: #f5fcff !important;
}

/* 08-10-24 end */

.end-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: none !important;
}

/* -------27-07-------- */
.staff_atten_cus {
    width: 100% !important;
}

#confetti {
    position: absolute !important;
    z-index: 99999;
}

/* 31-07-2024 */

.set-bord.left-arr::active {
    background: #f3f3f7 !important;
}

.mandatory {
    color: red;
}

.btn-search-bar input {
    border: none !important;
}

.btn-search-bar {
    padding: 1px 8px !important;
}

.btn-search-bar.focused {
    border-color: #D9DCE6 !important;
}

.search-input {
    border-color: #fff !important;
    --tw-ring-color: #fff !important;
}

.rbc-event {
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding: 5px;
    box-sizing: border-box;
}

.rbc-row-segment .rbc-event-content {
    overflow: hidden !important;
}

.event-field p {
    word-wrap: break-word;
    overflow: auto;
}

.rbc-toolbar .arrow-btn-group button:active,
.rbc-toolbar .arrow-btn-group button.rbc-active,
.rbc-toolbar .arrow-btn-group button:focus {
    background: #F3F3F7 !important;
}

.rbc-toolbar .glo-showdow:active,
.rbc-toolbar .glo-showdow.rbc-active,
.rbc-toolbar .glo-showdow:focus {
    background: #F3F3F7 !important;
}

.hide-visibility {
    visibility: hidden;
}

#assign-teacher .btn-search-bar {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* 12.09 */
}

#assign-teacher .btn-search-bar input {
    min-width: 284px !important;
}

/* 
.icon-Close {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 5px;
} */

.rbc-overlay {
    height: 400px;
    overflow: auto;
    top: 10% !important;
}

.rbc-overlay::-webkit-scrollbar {
    width: 8px;
}

.rbc-overlay::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.rbc-overlay::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* 26-8-2024 */
.event-calendar span {
    display: none;
}

/* 27-8-2024 */
.rbc-addons-dnd-drag-preview,
.rbc-event-drag-preview {
    background-color: blue !important;
    color: white !important;
    border: none;
    /* Optionally remove the border */
}

.create-event-info {
    color: #FFFFFF !important;
}

.rbc-overlay>*+* {
    border-left-color: #33bfff !important;
    border-right-color: #33bfff !important;
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -10px !important;
    width: 100% !important;
    border-left-color: transparent !important;
}

.rbc-event-continues-after {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-color: #fff !important;
    padding-right: 0px !important;
    width: 100% !important;
    margin: 0px 0px 0px 10px !important;
}

.rbc-overlay>*+* {
    margin: 15px 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

/* 28-8-2024 */

.selected-date .rbc-events-container .rbc-event {
    background: none !important;
    border: none !important;
}

.selected-date .rbc-events-container .rbc-event .rbc-event-content .custom-event div {
    color: #FFFFFF !important;
}



/* ----------akash 28-08--------- */

.ant-picker-dropdown .ant-picker-content th {
    text-transform: uppercase;
    color: #8A90A4;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

}

.ant-picker-cell-in-view .ant-picker-cell-inner {
    color: #000000;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
    width: auto;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}



.ant-picker-dropdown .ant-picker-cell {
    padding: 2px 0;
}

.ant-picker-footer {
    display: none;
}

.ant-picker-header .ant-picker-header-super-prev-btn {
    display: none !important;
}

.ant-picker-header .ant-picker-header-prev-btn {
    display: none !important;
}

.ant-picker-header .ant-picker-header-next-btn {
    display: none !important;
}

.ant-picker-header .ant-picker-header-super-next-btn {
    display: none !important;
}

.ant-picker-dropdown .ant-picker-header-view>button {
    color: #000000;
    border: 1px solid #D9DCE6;
    padding-inline: 10px;
    line-height: 1.9;
    border-radius: 5px;
}

.img_wid_dis img {
    height: 22px;
}

.btn-search-bar.flexc.glo-showdow {
    height: 40px;
    position: relative;
}

/* 
.btn-search-bar.flexc.glo-showdow input {
    height: 35px;
} */

.content.content_style_view ul li {
    list-style: initial;
    /* padding-left: 15px; */
}

.content.content_style_view ol li {
    list-style: auto;
    /* padding-left: 15px; */
}

.content.content_style_view ul {
    padding-left: 20px;
}

.content.content_style_view ol {
    padding-left: 20px;
}

/* ----------------------29-08------------------- */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #485cf0;
}

.ant-picker-dropdown .ant-picker-header-view>button:hover {
    color: #485cf0;
}

.text-cap {
    text-transform: capitalize;
}

.sp-dashboard-container {
    background: #F8F9FA;
}

/* 29-8-2024(sakthi) */
/* 08-10-24 start */
.new-event,
.custom-event-content,
.custom-rbc-event {
    /* background: #6474AF !important;
    border: 2px solid #6474AF !important; */
    /* border: 2px dashed #33bfff !important;
    background-color: #f5fcff !important;
    color: #ffffff;
    height: 100%; */
}

.selected-date .rbc-events-container .rbc-event {
    border: 2px dashed #33bfff !important;
    background-color: #f5fcff !important;
    color: #ffffff;
    height: 100%;
}

.rbc-month-row .selected-date .new-event {
    border: 2px dashed #33bfff !important;
    background-color: #f5fcff !important;
    color: #ffffff;
}

/* .rbc-event, .rbc-day-slot .rbc-background-event, .rbc-event{
    border: 2px dashed #33bfff !important;
    background-color: #f5fcff !important;
} */
/* 08-10-24 end */

.custom div,
.program div {
    height: 200px !important;
    overflow-y: auto !important;
}

.selected-event .custom-event div {
    color: #FFFFFF !important;
}

.rbc-event {
    padding: 0px !important;
}

.custom-event {
    padding: 10px !important;
}

.new-event div {
    /* color: #6474AF !important; */
    color: #f5fcff !important;
}

.upcoming {
    padding-bottom: 10px !important;
    padding-top: 5px !important;
}

.dash-right-activ3 {
    overflow-y: auto !important;
}

.dash-right-activ3::-webkit-scrollbar {
    width: 5px;
}

.dash-right-activ3::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.dash-right-activ3::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* --------30-08----- */
.activity_page .activity-container {
    background: #F8F9FA;
}

/* 30-8-2024(sakthi) */

.rbc-event-content .multi-day-event {
    background-color: #e0f7fa;
    border-left: 2px solid #29b6f6;
    border-right: 2px solid #29b6f6;
}

/* .rbc-event-content .new-event {
    background-color: #bbdefb;
    border-left: 2px solid #1e88e5;
    border-right: 2px solid #1e88e5;
} */

.rbc-toolbar button:focus {
    border-color: #D9DCE6 !important;
}


/* 31-08-2024(sakthi) */
.fixed-height-textarea {
    resize: none !important;
}

.rbc-overlay div[type="popup"].rbc-event-continues-after {
    border: 2px solid #33bfff !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .dashboard-cont {
        flex-wrap: wrap !important;
    }

    .dashboard-cont-left,
    .dashboard-cont-right {
        max-width: 725px !important;
        width: 100% !important;
        min-width: 725px !important;
    }

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 355px !important;
    }

    .dash-row3-box {
        max-width: 172px !important;
    }

    .dashboard-cont-right {
        display: flex !important;
        flex-wrap: wrap;
    }

    .dashboard-cont-right .sp-dashboard {
        max-width: 350px !important;
        margin-bottom: 0px !important;
    }

    .sp-activity-row2 {
        row-gap: 0px !important;
    }

    .sp-activity-row2 img {
        width: 24% !important;
    }

    .activity-colum {
        max-width: 160px !important;
        padding: 4px 0px !important;
        justify-content: flex-start !important;
    }

    .dis_fe_st_pos {
        padding-left: 0px !important;
        justify-content: flex-start !important;
    }
}





.pad_sid_ac {
    padding-inline: 1.5rem;
}

.bordertop-act {
    border-top: 1px solid #D9DCE6;
    /* padding-top: 15px; */
    margin-top: 0px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

/* 10.09.2024 */

.my_pro_div {
    padding: 9px 0 !important;
}

.log_out_set {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
}

.log_out_set p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #344054;
}

.log_out_set .relative.inline-flex.items-center.justify-center.overflow-hidden {
    width: 24px;
    height: 24px;
    background: #324AFF;
    border-radius: 8px;
}

.log_out_set .relative img {
    width: 24px;
    height: 24px;
    border-radius: 8px;
}

.log_out_set .relative.inline-flex.items-center.justify-center.overflow-hidden span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 10px;
    text-align: left;
    color: #FFFFFF;
}

.my_pro_div .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul li {
    padding-block: 5px;
    margin-inline: 5px;
}

.my_pro_div .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul li button {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #344054;
}

.my_pro_div .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow {
    width: 100%;
    box-shadow: 0px 6px 14px 0px #0000001A;
    border: 1px solid #D9DCE6;
    border-radius: 6px;
}

.my_pro_div button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.my_pro_div svg {
    color: #3D3D3D;
}

.noti_no_process h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: #000000;
    padding-bottom: 8px;
}

.noti_no_process p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #797979;
}

.tab_pro_sett .bg-gray-100 {
    background-color: transparent;
    border-bottom: 2px solid #4D6EE3;
    padding-bottom: 6px;
    padding-inline: 6px;
}

.tab_pro_sett button {
    padding-top: 0;
    padding-bottom: 8px;
    padding-inline: 6px;

}

.setting_page .field-value-holder button {
    height: 40px;
    padding-bottom: 0;
}

.tab_pro_sett .hover\:text-gray-600:hover {
    background-color: transparent;
    /* border-bottom: 2px solid #4D6EE3; */
    padding-bottom: 8px;
}

.tab_pro_sett .flex.text-center.flex-wrap.border-b.border-gray-200 {
    border-bottom: 1px solid #E8EAF2;
    gap: 10px;
    padding-left: 3pc;
    background-color: white;
}

.back-white {
    background-color: #fff;
}

.setting_page .sp-right-panel {
    background: #F8F9FA;
    height: 100vh;
}

.profile_page {
    padding-left: 3pc;
    height: 100vh;
    overflow: auto;
    padding-bottom: 8pc;
}

.profile_page .max-w-sm {
    max-width: 53rem;
}

.profile_page .field-label label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #1B252F;
}


.profile_page .per_info_pro {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.per_info_pro h5 {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: left;
    color: #344054;
}

.per_info_pro .profile_cancel {
    border: 1px solid #D7D9DD;
    padding: 6px 12px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
}

.per_info_pro .profile_cancel img {
    padding-right: 5px;
}

.per_info_pro .profile_save {
    background: #4D6EE3;
    border: 1px solid #4D6EE3;
    padding: 6px 12px;
    border-radius: 6px;
    display: flex;
}

.per_info_pro .profile_cancel:hover {
    background: #f3f3f7;
}

.per_info_pro .profile_save:hover {
    background: #3e52c1;
}

.per_info_pro .dip_fle {
    display: flex;
}

.per_info_pro .profile_cancel span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #000000;
}

.per_info_pro .profile_save span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #FFFFFF;
}

.m-25 {
    margin-block: 25px;
}

.pro_pss_lock img {
    border: 1px solid #EBEDF5;
    box-shadow: 0px 2px 5px 0px #0000000D;
    border-radius: 10px;
}

.pro_pss_lock {
    display: flex;
    gap: 10px;
    align-items: center;
}

.pro_pss_lock h4 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #373943;
    margin-bottom: 4px;
}

.pro_pss_lock p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #373943;
    margin-top: 5px;
}

.pro_pss_edit {
    display: flex;
    gap: 10px;
    align-items: center;
}

.pro_pss_edit h4 {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    color: #373943;
}

.pro_pss_edit p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    color: #373943;
}

.flex_pass_start {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.info_details_profi {
    width: 100%;
    display: flex;
    padding-block: 15px 0px;
}

.info_details_profi .pro_pss_lock {
    width: 50%;
}

/*  */
.Schoolprofile_page {
    padding-left: 3pc;
    height: 100vh;
    overflow: auto;
    padding-bottom: 8pc;
}

.Schoolprofile_page .max-w-sm {
    max-width: 53rem;
}

.Schoolprofile_page .per_info_pro {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.school_name h4 {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: #373943;
    margin: 0;
}

.school_name p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #373943;
    margin: 0;
}

.wid_100 {
    display: flex;
    width: 100%;
}

.wid_100 .wid_50 {
    width: 50%;
}


.pbb-15 {
    padding-block: 15px;
}

.school_infor_pro h4 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #373943;
    margin: 0;
}

.school_infor_pro p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #373943;
    margin: 0;
}

.size_small_image img {
    width: 2rem;
    height: 2rem;
}

.scool_info_list p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
    color: #000000;
}

.logo_update_stud img {
    background: #8D99F6;

}

.logo_update_stud .relative {
    border: 1px dashed #59758A;
    padding: 3px;
    border-radius: 10px;
}

.scool_info_list .change_logo_onbor {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
    color: #485CF0;
}

.scool_info_list .remove_logo_onbor {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #F14B4B;
}

.scool_info_list .dis_flex_first {
    display: flex;
    gap: 10px;
}

.scool_info_list .dis_flex {
    display: flex;
    gap: 10px;
}

.scool_info_list .totl_dis_change {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.pt-15 {
    padding-top: 15px;
}

.school_info_drop_down button span {
    color: #373943;
}

/*  */

/* / -------bharathi 10-09------- / */
.logo-component {
    border-radius: 7px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
}


.onbor_div_input .field-label {
    margin-top: 25px;
}

.onbording_main .logo_sec_after img {
    background: unset;
    padding: 0px;
    width: 50px;
    height: 50px;
    border: 1.9px dashed #59758A;
    padding: 3px;
    border-radius: 10px;
}

.logo_error_mes {
    position: absolute;
    left: 0;
    top: 3.5pc;
}

.total_error .error.pos_abo_stu {
    color: red;
    font-size: 12px;
}

.school_info_back_btn {
    float: left;
    background: #FFFFFF !important;
    border: 1px solid #D7D9DD !important;
    color: #525760 !important;
    font-family: Inter;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: inherit !important;
    padding: 9px 0 !important;
}

.school_info_back_btn:before {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 1px solid #525760;
    border-right: 1px solid #525760;
    transform: rotate(-132deg);
    font-weight: 500;
    margin-right: 10px;
    top: -1px;
    display: inline-block;
    position: relative;
    transition: all 0.4s ease;
    right: 0;
}

.p-o {
    padding: 0;
}

/* 12-09-2024 start */
.leave-widget-highlight {
    border: 1px solid #4D6EE3 !important;
}

/* 12-09-2024 end */


/* 24.09.2024 */
.css-1d3fd7w-MuiPaper-root-MuiSnackbarContent-root {
    min-width: auto !important;
}

.nodata_centen_imag {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-right-panel .top_bar_cus.back-white {
    height: 71px;
    padding-left: 3pc;
}

/* .fs-15 {
    font-size: 15px;
} */

.dis_fle_progra {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.titdes_scrool textarea:focus {
    border: 0px solid transparent !important;
    --tw-ring-color: transparent !important;
}

.titdes_scrool textarea::placeholder {
    color: #424242ad;
}

.titdes_scrool .text_inline_pad {
    padding-inline: 10px 16px;
}