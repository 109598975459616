@import url(reset.css);
@import url(fonts.css);

* {
    margin: 0;
    padding: 0;
}


.dis-block {
    display: block;
}

body {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    color: var(--sp-normal-clr);
    overflow: hidden;
    line-height: 1;
    /* background-color: #f9f9f9; */
}

.sp_sticky {
    position: sticky;
}

.dropdown-helper-btn {
    padding: 5px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid var(--sp-border-menu);
}

.dropdown-helper-btn li {
    padding: 6px 10px;
    border-radius: 4px;
    transition: all 0.5s ease;
}

.dropdown-helper-btn li:hover {
    background-color: #F3F3F7;
}

.dropdown-helper-btn li:not(:last-child) {
    margin-bottom: 5px;
}

.sp-table-body .row img,
.students-add-list ul li img {
    width: 22px;
    /* height: 22px; */
}

.img_wid_cu img {
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

.sp-mail-ft {
    opacity: 0.5;
}

textarea {
    line-height: 1.5rem;
}

.full-field {
    width: 100%;
}

input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

[class*="sp-btn-"] {
    padding: 10px 15px;
    display: inline-block;
    background-color: var(--sp-theme-color2);
    border-radius: 6px;
}

/* input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
} */

/* [class*="sp-btn-"] {
    padding: 10px 15px;
    display: inline-block;
    background-color: var(--sp-theme-color2);
    border-radius: 6px;
} */

.sp-btn-cancel {
    background-color: #fff;
    border: 1px solid var(--sp-br-clr);
    margin-right: 20px;
    border-radius: 6px;
    padding: 12px 18.72px;
    color: #1B2236;
    font-size: 14px;
    font-weight: 400;
}

.icon-More-Icon {
    margin-left: 10px;
}

.students-add-list>ul {
    width: 100%;
    display: flex;
}

.students-add-list {
    border-top: 1px solid #E8EAF2;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 158px);
    position: relative;
    /* min-width: 1100px; */
    padding-bottom: 30px;
}

.sp-selected-col {
    background-color: var(--sp-theme-clr2);
    color: var(--sp-secondary-theme-clr);
}

.sp-selected-bt {
    background-color: #F2F4F8;
    border: 1px solid #F2F4F8;
    padding: 5px 8px;
    border-radius: 4px;
}

.but_update_div .sp-selected-bt {
    background-color: unset;
    border: unset;
    padding: 0px;
    font-weight: 400;
    font-size: 12px;
}

.sp-view-btn {
    cursor: pointer;
}

.sp-select-active-col {
    position: fixed;
    top: 0;
    height: 100%;
    border: 1px solid var(--sp-secondary-theme-clr);
    width: 168px;
    right: 440px;
    z-index: 23;
}
.warp_div_food{
    display: flex;
    flex-wrap: wrap;  
    align-items: center;
}
.students-add-list>ul>li {
    padding: 15px 0;
    max-width: 200px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E8EAF2;
    border-right: 1px solid #E8EAF2;
    font-weight: 500;
    position: relative;
}

.students-add-list>ul>li.sp-selected:before,
.students-add-list>ul>li.sp-selected:after {
    content: '';
    position: absolute;
    background-color: var(--sp-secondary-theme-clr);
    height: calc(100% + 1px);
    width: 1px;
    left: 0;
    top: 0;
}

.students-add-list>ul>li.sp-selected:after {
    left: auto;
    right: 0
}

.students-add-list>ul:last-child li.sp-selected {
    border-bottom: 1px solid var(--sp-secondary-theme-clr);
}

.students-add-list>ul:first-child li.sp-selected {
    border-top: 1px solid var(--sp-secondary-theme-clr);
}

.sp-empty-state {
    background-color: var(--sp-theme-clr1);
}

.students-add-list>ul>li:first-child {
    /* min-width: 230px; */
    min-width: 275px;
    width: 100%;
    padding-left: 10px;
    text-align: left;
    justify-content: initial;
    padding-left: 12px;
    margin-left: 0px;
    font-weight: 600;
}

.li_heigh_44 li {
    height: 44px;
}

.hei_fix_4 li {
    height: 40px;
}

.staff_atten_cus .pad_30_ex {
    padding-left: 22px;
}

.staff_atten_cus .sp-header nav {
    padding-left: 22px;
}

.staff_atten_cus .pad_ic_ri {
    /* padding-left: 17px; */
    padding-left: 23px;
}

.food_cus .sp-menugroup {
    padding-left: 22px;
}


.food_cus .students-add-list>ul>li:first-child {
    padding-left: 45px;
}



.food_cus .pad_ic_ri {
    /* padding-left: 17px; */
    padding-left: 23px;
}

.students-add-list>ul>li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.sp-head {
    top: 0;
    background-color: #fff;
    z-index: 99;
}

.sp-head li {
    font-size: 0.8125em;
}

.tick-ele,
.cross-ele,
.notapp-ele {
    display: block;
    margin: 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    /* background: #01a768; */
    position: relative;
}

.notapp-ele::before {
    content: '!';
    font-size: 9px;
    display: block;
    color: rgb(255, 255, 255);
}

.notapp-ele {
    background: #e25050;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.cross-ele::before,
.cross-ele::after {
    content: '';
    display: block;
    width: 7px;
    height: 1.5px;
    background: #fff;
    border: 0;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.cross-ele {
    background: #FC9612;
}

.cross-ele:after {
    transform: rotate(-45deg);
}

.search-cont {
    max-width: 300px;
    width: 100%;
    height: 36px;
}

.search-cont img {
    margin: 0 10px;
}

.attence-menu {
    padding: 20px 0;
}

input::placeholder {
    text-align: left;
}

.sp-selected-bt .icon-Button-Plus:before {
    font-size: 16px;
    margin-right: 0;
}

.week-detail {
    width: 170px;
    height: 36px;
    gap: 5px;
}

.week-detail .left-arr,
.week-detail .right-arr {
    width: 38px;
    height: 100%;
}

.set-bord {
    /* border: 1px solid #D9DCE6; */
    border-radius: 8px;
}

.week-detail button .arrow {
    position: relative;
    display: inline-block;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    width: 6px;
    height: 6px;
    transition: transform .3s ease;
}

/* .left-arr span {
    transform: rotate(-135deg);
} */

.sel-days {
    max-width: 75px;
    width: 100%;
    /* height: 100%; */
}

.up-arr {
    transform: rotate(135deg);
    top: -2px;
    left: 5px;
}

.attence-list1 {
    max-width: 500px;
    width: 100%;
    justify-content: space-between;
}

.attence-list2 .add-icon {
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

.attence-list2 img {
    margin: 0 3px;
}

.attence-list2 span {
    font-weight: 500;
    margin-right: 10px;
}

.attence-menu {
    width: 97%;
    margin: 0 auto;
    justify-content: space-between;
}

.sp_mbtn {
    padding: 11px 0.875em;
    border: 1px solid var(--sp-border-menu);
    border-radius: 6px;
    margin-right: 10px;
    font-size: 0.8125em;
}

.present_absent_height {
    height: 60px;
}

.present_absent_height .sp_mbtn {
    padding: 8px 0.875em;
}

.moveTo {
    padding: 0.665em 0.875em;
    border-radius: 6px;
}


/* my code */

/* .students-add-list>ul>li {
    padding: 10px 6px;
    max-width: 150px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E8EAF2;
    border-right: 1px solid #E8EAF2;
    font-weight: 500;
} */
.students-add-list>ul>li {
    border-bottom: 1px solid #e8eaf2;
    border-right: 1px solid #e8eaf2;
    font-weight: 500;
    /* max-width: 150px; */
    max-width: 200px;
    padding: 10px 3px;
    text-align: center;
    width: 100%;
}
.cross-ele:after {
    transform: rotate(-45deg);
}

.search-cont {
    max-width: 300px;
    width: 100%;
    height: 36px;
}

.search-cont img {
    margin: 0 10px;
}

.attence-menu {
    padding: 20px 0;
}

/* input::placeholder {
    text-align: left;
} */

.arrow {
    position: relative;
    display: inline-block;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    width: 6px;
    height: 6px;
    transition: transform .3s ease;
}

.attence-list1 {
    max-width: 500px;
    width: 100%;
    justify-content: space-between;
}

.attence-list2 .add-icon {
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

.attence-list2 img {
    margin: 0 3px;
}

.attence-list2 span {
    font-weight: 500;
    margin-right: 10px;
}

.attence-menu,
.dashboard-inr {
    width: 97%;
    margin: 0 auto;
    justify-content: space-between;
}

.dashboard-top button {
    padding: 10px 20px;
    background: var(--sp-theme-color2);
    color: #FFF;
    border-radius: 6px;
}

.dashboard-inr {
    padding: 20px 30px;
    width: 100%;
    max-width: 1460px;
    margin: 0;
}

.dashboard-cont-left {
    width: 69%;
    /* min-width: 800px  08-10-24*/
}

.dash-row1 .dash-row1-box {
    /* max-width: 220px; 08-10-24 */
    width: 100%;
    padding: 20px 15px;
    border-radius: 6px;
    gap: 10px;
}

.dash-row1 .dash-row2-box {
    gap: 10px
}

.dash-row1-box1 {
    border: 1px solid #D9DCF5;
    background: #E9EBFD;
}

.dash-box1-btm {
    margin-top: 15px;
}

.dash-box1-btm span:first-child {
    color: var(--sp-dark-blue);
}

.dash-row1-range {
    display: inline-block;
    width: 66px;
    height: 5px;
    background: #d2d3d9;
    border-radius: 10px;
    position: relative;
}

.dash-row1-range::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 60%;
    height: 100%;
    background: var(--sp-dark-blue);
    border-radius: 10px;
}

.dash-box1-btm img {
    max-width: 25px;
}

.dash-row1-box2 {
    border: 1px solid #F4C8CB;
    background: #FFD4D7;
}

.dash-row1-box2 .dash-box1-btm span:first-child {
    color: #9B020D;
}

.dash-row1-box3 {
    border: 1px solid #BCDDF8;
    background: #D6EDFF;
}

.dash-row1-box3 .dash-box1-btm span:first-child {
    color: #003D6D;
}

.dash-row1-box4 .dash-box1-btm span:first-child {
    color: #794900;
}

.dash-row1-box4 {
    border: 1px solid #F2D6AD;
    background: #FFECCD;
}

.dash-bar-chart,
.dash-row4-activity {
    max-width: 465px;
    width: 100%;
    padding: 20px;
}

.chart-colum-cont span {
    display: block;
    width: 28px;
    border-radius: 6px;
    margin: 0 auto 15px;
}

.chart-colum-cont .chart-colm1 {
    height: 84px;
    background: var(--sp-light-indigo);
}

.chart-colum-cont .chart-colm2 {
    height: 147px;
    background: var(--sp-light-mint);
}

.chart-colum-cont .chart-colm3 {
    height: 105px;
    background: var(--sp-dark-blk);
}

.chart-colum-cont .chart-colm4 {
    height: 168px;
    background: var(--sp-secd-blue);
}

.chart-colum-cont .chart-colm5 {
    height: 63px;
    background: var(--sp-secd-cyan);
}

.chart-colum-cont .chart-colm6 {
    height: 126px;
    background: var(--sp-secd-light-green);
}

.bar-chart-cont ul {
    float: left;
}

.chart-colum-cont,
.bar-chart-cont {
    align-items: end;
}

.bar-chart-cont ul:first-child li {
    margin-bottom: 40px;
}

.chart-colum-cont li {
    margin: 0 15px;
    text-align: center;
}

.bar-chart-cont {
    margin-top: 20px;
}

.dash-row3-box {
    max-width: 220px;
    height: 160px;
    width: 100%;
    position: relative;
    padding: 15px;
    text-align: left;
}

.dash-row3-box span:first-child {
    padding: 8px 16px;
    border-radius: 30px;
    font-size: 0.625em;
    display: inline-block;
    margin-bottom: 20px;
}

.dash-row3-box:nth-child(odd) span:first-child {
    color: #fff;
}

.dash-row3-box:nth-child(even) span:first-child {
    color: #000;
}

.sp-row3-bt1 {
    background: var(--sp-light-indigo);
}

.sp-row3-dt {
    position: absolute;
    bottom: 20px;
}

.sp-row3-bt2 {
    background: var(--sp-light-mint);
}

.sp-row3-bt3 {
    background: var(--sp-dark-blk);
}

.sp-row3-bt4 {
    background: var(--sp-secd-blue);
}

.dash-row3-box p {
    line-height: 1.3em;
    font-size: 0.875em;
}

.dashboard-cont-left p {
    font-size: 0.845em;
}

.activity-row1 button,
.dash-activ3-row button {
    border: 1px solid var(--sp-border-menu);
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0px #0000000D;
}

.activity-dt,
.sp-activ-cont .sp-activ-hr,
.activity-colum-cont span {
    font-size: 0.75em;
    color: var(--sp-secd-gray);
}

.sp-activities .sp-activ-bt {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    justify-content: center;
    margin-right: 15px;
    position: relative;
    z-index: 1;
}

.sp-activ-bt1 {
    background: #65CC81;
}

.sp-activ-bt2,
.sp-activ-bt3 {
    background: #A3647A;
}

.sp-activ-bt i::before {
    color: #FFFFFF;
    margin: 0;
    font-size: 15px;
}

.sp-activ-cont .sp-activ-hr {
    display: inline-block;
    margin-top: 6px;
}

.activity-row2 {
    position: relative;
    margin-top: 10px;
}

.activity-row2::before {
    content: '';
    position: absolute;
    display: block;
    height: 90%;
    width: 1px;
    background: #E2E2E2;
    z-index: 0;
    left: 3%;
    top: 3%;
}

.sp-activities,
.activity-row1 {
    margin-bottom: 25px;
}

.sp-activity-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #F6F6F6;
}

.activity-colum {
    max-width: 171px;
    width: 100%;
}

.activity-colum-cont p {
    margin-top: 5px;
    white-space: nowrap;
}

.sp-activity-row2 {
    flex-wrap: wrap;
    row-gap: 80px;
}

.dash-row4 {
    align-items: stretch;
}

.dashboard-cont {
    gap: 10px;
}

.dashboard-cont-right {
    width: 28%;
    /* min-width: 380px; 08-10-24*/
}

.dashboard-cont-right .activity-row1,
.dash-right-activ2,
.dash-activ3-row {
    padding: 15px;
    margin-bottom: 0;
}

.dash-right-activ2 {
    border-top: 1px solid var(--sp-border-clr);
    border-bottom: 1px solid var(--sp-border-clr);
}

.dash-right-activ3 img {
    max-width: 29px;
    margin-right: 10px;
}

.dash-right-activ3 p span {
    display: block;
    color: #000;
    margin-top: 5px;
}

.holi-dt-ic {
    width: 29px;
    height: 29px;
    background: var(--sp-light-indigo);
    border-radius: 8px;
    justify-content: center;
    margin-right: 10px;
}

.holi-dt-ic i::before {
    margin-right: 0;
    color: #FFF;
}

.activity-inr {
    max-width: 720px;
    margin: 0 auto;
    padding-top: 20px;
}

.act-announce-inp img {
    max-width: 38px;
    margin-right: 20px;
}

.act-announce-inp input {
    width: 100%;
}

.act-announce-sh {
    padding: 20px;
}

.announce-post-left {
    max-width: 400px;
    width: 100%;
}

.dic_width {
    display: inline-block;
    max-width: auto;
    width: auto;
}

.announce-post-left.dic_width .sp-post-bt {
    padding: 7px;
    border-radius: 8px;
    background: #F2F4F8;
    /* width: 100%; */
    position: relative;
    margin-right: 10px;
}

/* .announce-post-left span:first-child {
    max-width: 154px;
} */

/* .announce-post-left span:nth-child(2) {
    max-width: 220px;
} */

/* .announce-post-left span:nth-child(3) {
    max-width: 91px;
} */

.sp-post-bt .arrow {
    margin-left: 5px;
}

.announce-post-cont button {
    background: var(--sp-blue-clr);
    border-radius: 8px;
    padding: 9px 15px;
    color: #FFF;
}

.announce-post-cont button i::before {
    color: #FFF;
}

.announce-post-inr {
    /* margin-top: 60px; */
    display: none;
}

.annonce-pers-left-cont .sp-crt-dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #DEE0E8;
    border-radius: 50%;
    margin: 0 7px;
}

/* .annonce-pers-left img,
.msg-conves-cont img {
    max-width: 38px;
    border-radius: 8px;
} */

.annonce-pers-left img,
.msg-conves-cont img {
    max-width: 32px;
    height: 30px !important;
    border-radius: 8px;
}

.annonce-pers-left.flexc {
    width: 100% !important;
    /* padding-bottom: 10px; */
}

.annonce-pers-left-cont {
    width: 85%;
    cursor: text;
}

.annonce-pers-left-cont .post-re-tm {
    color: #686C7B;
    display: inline-block;
    /* margin-top: 8px; */
}

.annoce-poster-inr p {
    margin-top: 5px;
    line-height: 20px;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    color: #1B252F;
}

.annoce-poster-inr p:last-child {
    /* margin-top: 50px; */
}

.annoce-poster-inr {
    padding-top: 10px;
    /* border-bottom: 1px solid var(--sp-border-clr); */
}

.annoce-poster-inr p:last-child span {
    display: block;
}

/* .annoce-poster-btm span {
    margin-right: 20px;
} */
.annoce-poster-btm span {
    margin-right: 6px;
}

.annoce-poster-btm span:last-child {
    margin-right: 0px;
}

.sp-msg-sec-left {
    width: 30%;
}

.msg-sec-left-row1 button {
    width: 34px;
    height: 34px;
    background: var(--sp-blue-clr);
    border-radius: 8px;
    color: #FFF;
    font-size: 1.25em;
}


/* .sp-msg-sec-left .btn-search-bar {
    margin: 20px 30px 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
} */

.msg-per-name {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    color: #FFF;
    margin-right: 10px;
    justify-content: center;
    font-size: 18px;
}

.msg-name1 {
    background: var(--sp-orange-clr);
}

.msg-wh-inr .msg-person-inr:first-child {
    background: #F6F7FB;
    border-radius: 12px;
}

.msg-wh-inr .msg-person-inr {
    padding: 20px;
    position: relative;
}

.msg-person-inr:not(.msg-person-inr:first-child),
.msg-sec-left-row1,
.sp-msg-sec-right .msg-person-inr {
    border-bottom: 1px solid var(--sp-border-clr);
}

.msg-wh-inr {
    margin: 0 20px;
}

.sp-msg-sec-left {
    border-right: 1px solid var(--sp-border-clr);
}

.msg-send-tm,
.msg-unread-num {
    position: absolute;
    right: 12px;
}

.msg-name2 {
    background: var(--sp-pink-clr);
}

.msg-unread-num {
    width: 17px;
    height: 17px;
    background: #FB6060;
    color: #FFF;
    justify-content: center;
    font-size: 0.625em;
    border-radius: 4px;
}

.msg-name3 {
    background: var(--sp-secd-blue-clr);
}

.sp-msg-sec-right {
    width: 70%;
}

.sp-msg-sec,
.msg-conves-cont {
    align-items: initial;
}

.msg-conves-text span {
    padding: 10px 15px;
    border: 1px solid var(--sp-border-clr);
    border-radius: 8px;
    display: block;
    margin-bottom: 15px;
}

.sp-msg-sec {
    height: 100%;
}

.msg-conves-cont:nth-child(even) .msg-conves-text {
    margin-left: auto;
}

.msg-conves-btm button {
    width: 24px;
    height: 24px;
    border: 1px solid var(--sp-border-clr);
    border-radius: 6px;
}

.activ3-row1-left {
    text-align: left;
}

.msg-conves-btm {
    border-top: 1px solid var(--sp-border-clr);
}

.msg-conves-btm i {
    position: absolute;
    right: 0;
}

.activity-container {
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
}

.sp-table-import {
    width: 100%;
    height: calc(100vh - 100px);
}

.sp-import-container {
    max-width: 460px;
    width: 90%;
}

.file-img {
    width: 58px;
    margin: 0 auto;
}

.sp-top-box {
    text-align: center;
    padding: 108px 40px 50px;
    border: 1px dashed #84A9FF;
    border-radius: 20px;
    background-color: #F4F7FE;
}

.sp-top-box .icon-Import-Outline:before {
    color: #fff;
}

.sp-bt-box {
    border: 1px solid #CFD8EE;
    border-radius: 20px;
    background-color: #F3F5F7;
    padding: 20px;
}

.file-col {
    width: 17px;
}

.sp-add-food .icon-plus-w:before {
    font-size: 1.05em;
    margin-right: 0;
}

.dialog-model {
    top: 40px;
    width: 500px;
    box-shadow: 0px 8px 24px 0px #0000000F;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    z-index: 23;
    border: 1px solid #D9DCE6;
    display: none;
}

.dialog-model.w-min {
    width: 340px;
}

.dialog-model:after,
.dialog-model:before {
    /* content: ""; */
    /* 25.07.2024 - Remove hook in the model */
    position: absolute;
    top: -7px;
    right: calc(50% - 8px);
    /* right: calc(77% - 0px); */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    transition: opacity .4s ease;
}


/* .sp-add-food  .dialog-model:after,
.dialog-model:before{
  
    right: calc(50% - 8px)!important;
} */

.dialog-model:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    border-bottom-color: #D9DCE6;
    top: -9px;
}

@media screen and (min-width: 1591px) and (max-width: 1680px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 430px !important;
        width: 100%;
        padding: 20px;
    }
}

@media only screen and (min-width: 1551px) and (max-width: 1590px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 380px !important;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (min-width: 1491px) and (max-width: 1550px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 410px !important;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (min-width:1150px) and (max-width:1280px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 300px !important;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (min-width:1281px) and (max-width:1350px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 338px !important;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (min-width:1351px) and (max-width: 1490px) {

    .dash-bar-chart,
    .dash-row4-activity {
        max-width: 352px !important;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (max-width:1400px) {
    body {
        font-size: 0.875rem;
    }


}


sup {
    top: -.5em;
    line-height: 0;
    position: relative;
}


/*  */
/* 
[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
    background-color: unset !important;
    font-size: 0.8125em !important;
    border-radius: 8px;
} */
/* 
.focus\:ring-4:focus {
    --tw-ring-shadow: unset !important;
    --tw-ring-offset-shadow: unset !important;
    box-shadow: unset !important;
}

.focus\:ring-4:focus {
    --tw-ring-offset-shadow: unset !important;
    --tw-ring-shadow: unset !important;
}

.focus\:ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: unset !important;
} */

.dropbutton button {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.dropbutton svg {
    /* font-size: 0.925em;
    color: #3d3d3d; */
}

.dis_n_drop .dropbutton svg {
    display: none;
}

.dd_select {
    background-color: #F1F3F9;
    font-weight: 500;
    border-radius: 5px;
}


.field-value-holder .divide-gray-100.rounded.shadow {
    transform: translate(1px, 38px) !important;
    /* width: 50%; */
    min-width: unset !important;
    box-shadow: 0px 6px 14px 0px #0000001A;
    padding: 0px;
    border: 1px solid #D9DCE6;
    border-radius: 6px;
}

.margin_20p {
    margin-top: 20px;
}

.margin_top10p {
    margin-top: 10px;
}

/* 
.search_input_list [multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
    border-radius: 0px !important;
} */

.sp-search-top.flexc.search_input_list input {
    font-size: 0.8125em;
}


/* ------------------------28-05-2024 attendance-------------- */

.table_input_sty input {
    width: 18px;
    height: 18px;
    border: 1px solid var(--sp-border-menu);
    border-radius: 5px;
    margin-right: 30px;
    margin-left: 30px;
    cursor: pointer;
}

/* [type=checkbox]:focus,
[type=radio]:focus {
    --tw-ring-color: transparent;
} */

/* .icon_div_space i {
    color: black;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 500;
} */

.color_cha_whi {
    background-color: white !important;
    color: black;
    border: 1px solid;
    margin-right: 10px;
    font-weight: bold;
}

.color_cha_whi:before {
    color: black !important;
    font-weight: 900;
}

.leave_ic::before {
    background-color: black;
    height: 0.5px;
}




/* ------------------------28-05-2024 attendance-------------- */

.otp_font input {
    font-size: 26px !important;
}

/* .sp-field-input.error {
    border: 1px solid red;
 
} */

.sp-file-input {
    border: 1px solid #ccc;
    /* Default border */
    padding: 5px;
    /* Add some padding for better visual */
}

.sp-file-input.error {
    border-color: red;
    /* Error border */
}

/* [type=checkbox]:focus,
[type=radio]:focus {
    --tw-ring-color: transparent;
} */

.bold_li_div li {
    font-weight: bold !important;
}

/* .icon_div_space i {
    color: black;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 500;
} */

.leave_ic:after {
    background-color: black;
    height: 0.5px;
}


/*  */

.school-menu img {
    width: 16px;
}

.sp-bot-color {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    color: #fff;
    font-style: normal;
}

#schoolswitch ul {
    border-bottom: 1px solid #D9DCE6;
}

.sp-box {
    border: 1px solid #D9DCE6;
    box-shadow: 0px 2px 5px 0px #0000000D;
    max-width: 290px;
    width: 25%;
    gap: 10px;
    border-radius: 16px;
    text-align: center;
    padding: 20px;
    position: relative;
    transition: all 0.4s ease;
}

.sp-box .fig-img {
    width: 70px;
    height: 70px;
    border-radius: 16px;
    color: #fff;
    font-weight: 700;
}

.popup-manage-school .popup-wrap {
    height: calc(100vh - 80px);
    padding-bottom: 20px;
}

.fig-img img {
    width: 32px;
}

.sp-box p {
    color: #71778B;
    font-size: 13px;
    line-height: 1.4;
}

.sp-box:hover,
.sp-box.selected {
    border-color: var(--sp-blue-clr);
}

.sp-box .star-icon,
.sp-box .more-option {
    transition: all 0.4s ease;
}

.sp-box:hover .star-icon,
.sp-box:hover .more-option {
    opacity: 1;
    visibility: visible;
}

.sp-box .sp-view {
    border: 1px solid #D7D9DD;
    padding: 6px 16px;
    border-radius: 6px;
    transition: all 0.4s ease;
}

.sp-box .sp-view:hover {
    background-color: var(--sp-blue-clr);
    border-color: var(--sp-blue-clr);
    color: #fff;
}

.inset-y-x-2 {
    top: 10px;
    right: 10px;
}

.inset-x-l-2 {
    top: 10px;
    left: 14px;
}

.more-option:hover .edit-pop {
    opacity: 1;
    visibility: visible;
}

.edit-pop {
    width: 90px;
    border: 1px solid #D9DCE6;
    border-radius: 6px;
    box-shadow: 0px 6px 14px 0px #0000001A;
    top: 14px;
    left: -57px;
}

.edit-pop ul li {
    padding: 12px 15px;
    font-weight: 500;
    font-size: 0.875em;
}

.edit-pop ul li:hover {
    color: var(--sp-blue-clr);
}

.sp-box-manage {
    gap: 25px;
}

.sp-close-popup {
    box-shadow: 0px 2px 6px 0px #F4F6FF;
    background-color: #fff;
    width: 26px;
    height: 26px;
    left: -40px;
    top: 10px;
    border-radius: 8px;
    font-size: 8px;
}

.sp-close-popup i:before {
    margin-right: 0;
}

.sp-box .icon-rating-filled {
    color: var(--sp-blue-clr);
}

.round-btn {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background-color: #FFDEDE;
    color: #F34B4B;
}

.round-btn i:before {
    margin-right: 0;
}

.calender_del {
    width: 34px;
    height: 34px;
    border-radius: 50% !important;
    background-color: #FFDEDE;
    color: #F34B4B;
}

.calender_del i:before {
    margin-right: 0;
}

.close_time_table {
    border: 1px solid var(--sp-secondary-clr3);
    width: 34px;
    height: 34px;
    z-index: 22;
    background-color: #fff;
    color: #3D3D3D;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.leave_round_but {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #E7ECFF;
    color: #4D6EE3;
}

.leave_round_but i:before {
    margin-right: 0;
}

.search-items {
    max-height: 190px;
}

.search-items li {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 12px 18px;
    transition: all 0.4s ease;
    cursor: pointer;
}

.search-items li:hover {
    background-color: #EFF2F4;
    color: var(--sp-blue-clr);
}

.search-items li em {
    width: 3px;
    height: 3px;
    background-color: #333;
    border-radius: 50%;
    font-size: 0;
}

.search-items li:hover em {
    background-color: var(--sp-blue-clr);
}

.text-xsmall {
    font-size: 10px;
    font-weight: 600;
}

#assign-teacher {
    max-width: 400px;
    box-shadow: 0px 5px 12px 0px #0000001A;
}

#assign-teacher .btn-search-bar {
    max-width: 100%;
    margin: 0px 35px 0px 16px;
    box-shadow: 0px 2px 5px 0px #0000000D;
}

#assign-teacher .btn-search-bar input {
    min-width: calc(100% - 24px);
}

.small-tag {
    background-color: #8FA7FF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px !important;
    height: 18px;
    padding: 0px 7px;
    color: #fff;
    border-radius: 4px;
}


/* timetable start*/

.sp-timetab-inr {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 158px);
    position: relative;
    min-width: 1100px;
    padding-bottom: 30px;
}

.sp-time-cl .icon-Time:before {
    margin-right: 0;
}

.sp-menu2 {
    justify-content: end;
}

.sp-menu-togl {
    background: #F2F4F8;
    padding: 4px;
    margin-left: 15px;
    border-radius: 8px;
}

.sp-menu-togl span {
    width: 55px;
    justify-content: center;
    padding: 6px 0;
    cursor: pointer;
    border-radius: 6px;
}

.sp-menu-togl span.active {
    background: #FFF;
    box-shadow: 0px 2px 5px 0px #0000000D;
}

.sp-time-cl {
    width: 68px;
    height: 43px;
}

.sp-timetab-hd ul li {
    background: #fafbfc;
}

.sp-timetab-hd ul {
    border-top: 1px solid var(--sp-border-clr);
}

.sp-wk-days span,
.sp-time-cl,
.sp-timetab-week .sp-time-event ul li .week-day-box,
.sp-timetab-month .sp-time-event div {
    border-bottom: 1px solid var(--sp-border-clr);
    border-right: 1px solid var(--sp-border-clr);
}

.sp-wk-days span {
    height: 43px;
}

.sp-wk-days,
.sp-time-event {
    width: calc(100% - 68px);
}

.sp-wk-days span {
    width: 14.28%;
    font-weight: 500;
    font-size: 0.75em;
}

.sp-wk-days span,
.sp-time-hr span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sp-time-hr {
    width: 68px;
}

.sp-time-hour-inr {
    display: flex;
}

.sp-time-hr span {
    height: 100px;
    align-items: end;
    border-right: 1px solid var(--sp-border-clr);
    font-size: 0.75em;
}

.sp-time-event ul .time-event-colm,
.sp-time-event .week-day-box,
.sp-timetab-month .sp-time-event div {
    width: 14.28%;
}

.sp-timetab-week .sp-time-event ul li .week-day-box {
    display: block;
    height: 100px;
    width: 100%;
    position: relative;
}

.sp-timetab-month .sp-time-event div {
    height: 133px;
}

.sp-timetab-hd {
    top: 0;
    z-index: 99;
}

.sp-timetab-month .sp-wk-days,
.sp-timetab-month .sp-time-event {
    width: 100%;
}

.sp-timetab-month .sp-wk-days span {
    justify-content: end;
    padding-right: 10px;
    font-size: 0.75em;
}

.sp-timetab-month .sp-time-event div .month_dt {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 5px 5px 0 auto;
    justify-content: center;
    font-size: 12px;
}

.sp-timetab-month .sp-time-event div .mont-1st {
    width: 35px;
}

.last-month,
.upcom-month {
    color: var(--sp-secd-gray-clr);
}

.sp-timetab-month .sp-time-event div p span {
    display: block;
    color: #676A74;
    font-size: 11px;
    margin-top: 5px;
}

.sp-timetab-month .sp-time-event div p {
    width: 80%;
    margin: 0 auto;
    padding: 10px 0 10px 10px;
    background: var(--sp-dark-gray-clr);
    border-radius: 6px;
    line-height: 1.2;
}

.today_dt {
    background: var(--sp-blue-clr);
    color: #FFF;
}

.sp-timetab-sec {
    display: none;
}

.sp-timetab-sec.active {
    display: block;
}

.sp-time-event div.active {
    background: var(--sp-dark-gray-clr);
    position: relative;
}

.notify-cont .label-container {
    margin-bottom: 0;
}

.notify-cont label {
    display: block;
    margin-bottom: 12px;
}

/* 
.label-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
    margin-right: 10px;
}

.label-container input:checked~.tick-ele {
    background: #4D6EE3;
    border-color: #4D6EE3;
}

.label-container .tick-ele {
    background: #fff;
    border-color: #000;
}

.label-container .tick-ele {
    float: left;
    display: block;
    margin: 0 0 auto;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: 1px solid #3d3d3d;
    position: relative;
} */

/* .notify-cont input {
    padding: 16px 0 16px 15px;
    border: 1px solid #D9DCE6;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 0;
    transition: all 0.4s ease;
} */
/* 
.notify-cont input {
    margin-top: 10px;
    width: 100%;
} */

/* .label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;
} */

.notify-cont {
    margin-top: 10px;
}

.week-day-box .dialog-model {
    left: 100%;
    top: -80px;
}

.week-day-box .dialog-model:after,
.week-day-box .dialog-model:before {
    top: 113px;
    left: -13px;
    transform: rotate(-90deg);
}

.week-day-box.selected {
    background: #F2F4F8;
}

.fullsz-field {
    width: 100%;
}

.select_field_point:hover {
    background-color: #F4F6FF;
}

.span_acount p span {
    color: #325AE8;
}

select,
input,
button {
    font-size: 0.8125em;
}

.student_input,
.btn-search-bar {
    padding: 0px 7px;
}


/* 31 */

.customize_but {
    font-size: 0.8125em;
    /* padding: 7px 20px!important; */
}

.font_cus {
    font-size: 0.8125em;
}

.ft-13 {
    font-size: 0.8125em;
    /* line-height: 1; */
}

.dash-row1 .dash-row1-box {
    line-height: 1;
}

.dash_page .sp-search-top {
    margin-top: 20px;
}


/* activity post iamges*/
.post-images .image-row.one-column img {
    /* width: 700px;
    height: 409px; */
    border-radius: 10px;
}

.post-images .image-row.two-column img {
    width: 340px;
    height: 409px;
    border-radius: 10px;
    padding: 4px;
}

.post-images .image-row.three-column img {
    width: 32.5%;
    border-radius: 10px;
    height: 174.05px;
    border-radius: 10px;
}

.post-images .image-row.more-column img {
    width: 334px;
    height: 174.05px;
    border-radius: 10px;
    border-radius: 10px;
}

.more_num {
    font-size: 33px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    color: #FFFFFF;
}

.post-images .image-row.more-column {
    margin-top: 8px;
}

.plus_more_imgae {
    position: relative;
}

.more-images.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
}

.img-wrapper {
    height: 100vh;
    padding: 25px 0px;
}

/* .img-wrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
} */

.blur_image_style {
    filter: brightness(0.5);
}

.modal-lightbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
}

.modal-lightbox.visible {
    opacity: 1;
    visibility: visible;
}

.img-container {
    width: calc(100% - 430px);
}

.img-container img {
    width: 100%;
}

.img-container .slide-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #474A54;
    cursor: pointer;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.img-container .slide-arrow i:before {
    margin-right: 0;
}


.left-10 {
    left: 10px;
}

.right-10 {
    right: 10px;
}

.images-slide-box {
    width: 80%;
    max-width: 800px;
}

.message-box {
    width: 430px;
    background-color: #fff;
    scrollbar-width: none;
    padding-inline: 0;
}

.message-box .annoce-poster-inr p {
    margin-top: 5px;

}

.message-box .annoce-poster-inr {
    padding-bottom: 10px;
}

.profile-pic img {
    width: 32px;
    height: 32px;
}

/* ---01-08---- */
.comment-img img {
    border-radius: 7px;
}

/* img {
    width: 32px;
    height: 32px;
} */


.box-comment {
    width: calc(100% - 50px);
}

.activity_page .input-cmt {
    width: 100%;
    border: 1px solid #E8EAF2;
    border-radius: 20px;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    /* border-radius: 22px !important; */
    padding-right: 3pc;
}

.pos_rela_view {
    position: relative;
}

.comment-type {
    bottom: 0;
    padding: 10px 0;
    background-color: #fff;
}

#create-template-popup h2 {
    padding: 40px 40px 20px;
}

#create-template-popup .sp-pop-header {
    padding: 40px;
    padding-top: 0;
}

.btn-dropdown-fieldval .activity-temp li {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.btn-dropdown-fieldval li.new-doc {
    padding: 13px 8px 8px;
}

.active_col .arrow {
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    width: 8px;
    height: 8px;
}

.body_heig {
    line-height: 1;
}

.setgap {
    gap: 4px;
    flex-wrap: wrap;
    justify-content: center;
}

.body_heig .act-announce-inp input {
    font-size: 1rem !important;
    line-height: 1.5rem;
}

.halfplus-field {
    width: 67%;
}

.halfmin-field {
    width: 30%;
}

.text-decrt {
    padding: 10px;
    border-bottom: 1px solid var(--sp-border-clr);
}


/*  */

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.cp {
    cursor: pointer;
}

.overxy-auto {
    overflow: auto;
}


.help i:before {
    margin-right: 0;
}


/* Firefox */

.border0 {
    border: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.flexc {
    display: flex;
    align-items: center;
}

.justifySp {
    justify-content: space-between;
}

.flexf {
    flex-direction: column;
}

.alignT {
    align-items: flex-start;
}

.alignC {
    align-items: center;
}

.y-auto {
    overflow-y: auto;
    scrollbar-width: none;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
}

.overlay.visible {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
}



.load-container.clr-grey {
    width: 100%;
    background-color: #f9f9f9;
}

.sp-left-panel {
    width: 17.19em;
    position: fixed;
    left: 0;
    /* z-index: 999; */
    height: 100%;
    border: 1px solid var(--sp-border-clr);
    background-color: #fff;
    border-top-width: 0px !important;
}

.staff_atten_cus .sp-left-panel {
    z-index: 999;
    border-top-width: 0px;
}

.staff_atten_cus .sp-right-panel {
    width: calc(100% - 16em);
    margin-left: 17.159em;
    position: relative;
    /* max-width: 1600px; */
}

.sp-logo sup {
    color: var(--sp-clr-red);
    text-transform: uppercase;
    margin-left: 10px;
    font-weight: 700;
    font-size: 0.625em;
    vertical-align: text-top;
}

.sp-search-top {
    background-color: var(--sp-search-clr);
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 6px;
}

.sp-top-band {
    padding: 15px 15px 0;
}

.sp-middle-band {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 210px);
    display: flex;
    flex-direction: column;
}

.sp-title {
    font-size: 13px;
    color: var(--sp-ft-clr);
    font-weight: 600;
    /* letter-spacing: 0.5px; */
    margin: 1.25em 0;
}

.sp-left-panel .sp-title {
    color: var(--sp-ft-clr);
    margin: 1.25em 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.8px;
    text-transform: uppercase;
}

.sp-nav {
    padding: 0 15px 15px 18px;
}

.sp-nav li {
    padding: 12px 0;
    font-size: 0.875em;
    border: 1px solid transparent;
    position: relative;
}

.sp-nav li:before {
    content: '';
    width: calc(100% + 12px);
    background-color: transparent;
    height: 100%;
    position: absolute;
    left: -6px;
    border: 1px solid transparent;
    z-index: -1;
    top: 0;
    border-radius: 6px;
}

.sp-nav li:hover:before,
.sp-nav li.selected:before {
    background-color: #EFF2FF;
    border-color: var(--sp-hg-clr);
    box-shadow: 0px 1px 3px 0px #BEC4E033;
}

.sp-nav li:hover,
.sp-nav li.selected {
    color: var(--sp-blue-clr);
}

.sp-nav li.selected p {
    color: var(--sp-blue-clr);
    font-weight: 600;
}

.sp-nav li:hover [class^="icon-"]:before,
.sp-nav li.selected [class^="icon-"]:before {
    color: var(--sp-blue-clr);
}

.sp-nav li span {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sp-plus {
    width: 18px;
    height: 18px;
    border: 1px solid var(--sp-blue-clr);
    border-radius: 4px;
    color: var(--sp-blue-clr);
    text-align: center;
    cursor: pointer;
    line-height: 14px;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--sp-hg-clr);
}

.bor_rad {
    border-radius: 6px;
    box-shadow: unset !important;
}

.sp-bottom {
    border-bottom: 1px solid var(--sp-divider-clr);
    width: 100%;
    left: 0;
    padding: 15px;
    /* margin-bottom: 60px; */
    margin-bottom: 0px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    width: 17.13em;
}

.sp-top-bar {
    padding: 1.25em 1.625em;
}

.sp-view-btn {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    border: 1px solid var(--sp-br-clr);
    padding: 7px 17px;
    border-radius: 6px;
    background-color: #fff;
}

.sp-view-btn:hover {
    background-color: #F3F3F7;
}

.createN {
    background-color: var(--sp-blue-clr);
    color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
}

.space_img_pad img {
    padding-right: 10px;
}

.sp-header {
    margin-top: 10px;
}

.sp-list-view {
    font-size: 13px;
    color: var(--sp-secondary2-clr);
    margin-left: 10px;
    padding: 0 10px 10px;
    /* border-bottom: 2px solid transparent; */
    /* border-bottom: 0px solid transparent; */
    cursor: pointer;
    letter-spacing: normal;
}

.sp-header nav {
    border-bottom: 1px solid var(--sp-border-clr);
    padding-left: 30px;
}



.profile-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}


/*  */

/* .sp-table-container {
    overflow: hidden;
    min-width: 1200px;
    max-width: 1600px;
} */

.sp-table-container {
    overflow: hidden;
    min-width: 100%;
    max-width: 100%;
}

.btn-dropdown {
    font-size: 0.8125em;
    font-weight: 400;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--sp-border-menu);
    position: relative;
}

.sp-menugroup {
    padding-left: 30px;
}

.sp-menugroup>div:first-child {
    gap: 10px;
    flex: 1;
}

.sp-menu .btn-dropdown {
    padding: 0.565em 0.875em;
    border-radius: 6px;
}

.btn-search-bar {
    /* padding: 8px 7px; */
    border-radius: 8px;
    border: 1px solid var(--sp-border-menu);
    max-width: 300px;
}

.sp-menu-bar {
    padding: 0.75em 20px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-search-bar input::placeholder {
    color: #A9AFC3;
    font-weight: 500;
    font-size: 13px;
}

.btn-search-bar input {
    min-width: 248px;
    font-size: 0.8125em;
    padding: 7px 0;
    color: #1B252F;
    font-weight: 600;
    font-size: 13px;
}

.sp-menubar-clslist li {
    /* margin-left: 3px; */
    width: 18px;
    height: 18px;
}

.sp-menubar-clslist .sp-plus {
    background-color: transparent;
    border-color: var(--sp-br-clr);
    color: var(--sp-normal-clr);
    width: 22px;
    height: 22px;
    line-height: 1;
}

.sp-table-header {
    /* border-bottom: 1px solid var(--sp-border-menu); */
    border-bottom: 1px solid #E8EAF2;
    padding: 0 20px;
}

.sp-table-body .row {
    padding: 0px 20px;
    /* border-bottom: 1px solid var(--sp-border-clr); */
    border-bottom: 1px solid #E8EAF2;
    /* border-right: 1px solid #E8EAF2; */
}

.sp-table-body .row {
    /* background-color: #F4F6FF; */
}

.sp-table-body {
    height: calc(100vh - 158px);
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.sp-table-container .checkbox {
    flex: 0 0 2%;
    cursor: pointer;
}

.checkbox i {
    width: 18px;
    height: 18px;
    border: 1px solid var(--sp-border-menu);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox i.checked {
    background-color: var(--sp-theme-color2);
    border-color: var(--sp-theme-color2);
    text-align: center;
}

.checkbox i.checked:before {
    font-size: 0.4em;
    color: #fff;
    line-height: 1.6;
}

.sp-td-content {
    font-size: 0.8125em;
    color: var(--sp-tb-color);
}

.sp-tag-selected {
    background-color: var(--sp-tag-color);
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
}

.text-ellp {
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
    display: block;
    text-overflow: ellipsis;
}

@-webkit-keyframes fadeInright {
    0% {
        opacity: 0.8;
        -webkit-transform: translateX(300px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
}

@-moz-keyframes fadeInright {
    0% {
        opacity: 0.8;
        -moz-transform: translateX(300px);
        transform: translateX(300px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-o-keyframes fadeInright {
    0% {
        opacity: 0.8;
        -o-transform: translateX(300px);
        transform: translateX(300px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes fadeInright {
    0% {
        opacity: 0.8;
        -webkit-transform: translateX(300px);
        -moz-transform: translateX(300px);
        -o-transform: translateX(300px);
        transform: translateX(300px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }
}


/*  */

.popup-modal-container,
.popup-program-container,
.popup-manage-school {
    position: absolute;
    width: 50%;
    right: 0;
    background-color: #fff;
    height: 100%;
    z-index: 2;
    top: 0;
    max-width: 790px;
    transform: translateX(-10%);
    /* transition: all 0.6s ease; */
    opacity: 0;
    display: none;
}

.popup-program-container {
    max-width: 717px;
    z-index: 9999;
}

.popup-manage-school {
    opacity: 0;
    visibility: hidden;
}

.popup-modal-container.visible,
.popup-student-details.visible,
.popup-program-container.visible,
.popup-manage-school.visible {
    display: block;
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
    -webkit-animation-name: fadeInright;
    -moz-animation-name: fadeInright;
    -o-animation-name: fadeInright;
    animation-name: fadeInright;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.popup-manage-school.visible {
    opacity: 1;
    visibility: visible;
}

.popup-manage-school {
    max-width: 1320px;
    width: 80%;
}

.sp-pop-header {
    height: calc(100vh - 138px);
    overflow: scroll;
}

.popup-student-details .sp-pop-header,
.popup-modal-container .sp-pop-header {
    padding: 20px;
}

.half-field {
    width: 47%;
}

.popup-modal-container h2,
.popup-program-container h2 {
    font-size: 1.25em;
    padding: 20px;
}

.field-label {
    margin-top: 23px;
}

.field-label label {
    font-weight: 500;
    font-size: 0.8125em;
}

.field-value-holder {
    width: 100%;
    border: 1px solid var(--sp-border-menu);
    box-shadow: 0px 2px 5px 0px #0000000D;
    position: relative;
    border-radius: 8px;
}

.field-value-holder .icon-Timetable-Outline {
    right: 0;
}

.sp-field-select,
.sp-field-input {
    width: 100%;
    display: block;
    border: none;
    font-size: 0.8125em;
}

.sp-field-input {
    padding: 7px 16px;
    border-radius: 8px;
    /* color: #1B252F;
    font-weight: 600; */
}

:where(.css-dev-only-do-not-override-zg0ahe).ant-picker {
    padding: 4px 16px 4px;
}

.btn-dropdown-fieldval .icon-Tick:before {
    color: #3d3d3d;
    opacity: 0;
}

.btn-dropdown-fieldval.wd-auto {
    width: auto;
}

.btn-dropdown-fieldval {
    width: 200px;
    border: 1px solid var(--sp-border-clr);
    border-radius: 6px;
    padding: 6px;
    box-shadow: 0px 6px 14px 0px #0000001A;
    z-index: 2;
    background-color: #fff;
}

.btn-dropdown-fieldval li {
    font-size: 0.8125em;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}

.btn-dropdown-fieldval li.selected .icon-Tick:before {
    opacity: 1;
}

.btn-dropdown-fieldval li:hover,
.btn-dropdown-fieldval li.selected {
    background-color: #F1F3F9;
}

.datepicker-picker {
    border: 1px solid var(--sp-border-clr);
}

.datepicker-controls button,
.datepicker-controls button svg {
    font-size: 0.8125em;
    color: var(--sp-normal-clr);
    font-weight: 500;
}

.datepicker-cell {
    color: var(--sp-normal-clr);
    font-size: 0.8125em;
    font-weight: 500;
}

.datepicker-controls button svg {
    width: 0.8rem;
}

.sp-file-browse {
    width: 100px;
    padding: 13px 10px;
    background-color: #F9F9FA;
    border-radius: 6px;
    border-left: 1px solid var(--sp-border-menu);
    color: #1B252F;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
}

.sp-upload-btn {
    font-size: 0.8125em;
    width: 100%;
    padding-left: 10px;
    /* padding: .38px 0px .38px 10px; */
    padding: 0px 0px 0px 16px;
}

.sp-bottom-sticky {
    padding: 20px;
    box-shadow: 0px 0px 14px 0px #0000001A;
}

.sp-btn-create {
    color: #fff;
    margin-right: 10px;
    border-radius: 6px;
    padding: 13px 20.32px;
    font-size: 14px;
    font-weight: 400;

}

[class^="icon-"]:before {
    font-size: 1.25em;
    margin-right: 5px;
}

.space_icon_pad i {
    margin-right: 10px;
}

.icon-Down-Arrow:before {
    margin-right: 0;
}

.selected-icon {
    /* width: 28px;
    height: 28px; */
    border: 1px solid var(--sp-border-secondary-clr);
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.selected-icon i::before {
    margin-right: 0;
}

.icon-More-Icon {
    margin-left: 10px;
    font-size: 1.15em;
}

.icon-More-Icon .btn-dropdown .icon-Down-Arrow:before {
    margin-right: 0;
    margin-left: 5px;
}


/* Staff attendance */


/* my code */

.tick-ele,
.cross-ele,
.notapp-ele {
    display: block;
    margin: 0 auto;
    width: 16px;
    margin: 0px 5px;
    border-radius: 0px;
    /* background: #01a768; */
    position: relative;
}

.sp-lpdr {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.sp-lpdr {
    background-color: var(--sp-green-clr);
}

.icon-Tick:before {
    color: #fff;
    font-size: .925em;
    line-height: 20px;
}

.tick-ele:before {
    content: '';
    display: block;
    width: 6px;
    height: 7px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(40deg);
    position: absolute;
    top: 3px;
    left: 5px;
}

.cross-ele::before {
    content: '';
    display: block;
    width: 7px;
    height: .5px;
    /* background: #fff; */
    border: 0;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.cross-ele::after {
    content: '';
    display: block;
    width: 7px;
    height: .5px;
    /* background: #fff; */
    border: 0;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.up-arr {
    transform: rotate(135deg);
    top: -2px;
    left: 2px;
}

.notapp-ele {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

/*  */

.popup-student-details {
    max-width: 450px;
    width: 60%;
    border: 1px solid var(--sp-border-clr);
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 23;
    bottom: 0;
    box-shadow: 0px 5px 14px 0px #0000000D;
    transform: translateX(-10%);
    transition: all 0.8s ease;
    opacity: 0;
    visibility: hidden;
}

.popup-student-details:before {
    content: '';
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, #FAE8D6 0%, rgba(255, 226, 245, 0.8) 31.23%, #F4F0FF 70.82%, #FFFFFF 100%), radial-gradient(50% 74.51% at 50% 100%, rgba(255, 255, 255, 0.5) 24.09%, rgba(255, 255, 255, 0.16) 100%);
    width: 100%;
    height: 100px;
}

.student-details-page {
    padding: 60px 20px 40px;
    position: relative;
    height: calc(100vh - 20px);
    overflow: hidden;
    overflow-y: auto;
}

.sp-profile-head img {
    width: 62px;
    height: 62px;
}

.sp-top-details {
    align-items: self-end;
    justify-content: space-between;
}

.sp-status {
    background-color: var(--sp-theme-clr3);
    color: var(--sp-status-clr);
    padding: 4px 8px;
    border-radius: 8px;
    margin-left: 20px;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 600;
    text-align: left;

}

.sp-accbox {
    width: 34px;
    height: 34px;
    background-color: #ebeffa;
    border: 1px solid #e2e8f9;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
}

.sp-accbox::before {
    color: var(--sp-blue-clr);
}

.sp-accbox_edit_icon {
    width: 34px;
    height: 34px;
    background: #4D6EE31A;
    border: 1px solid #4D6EE31A !important;
    color: #4D6EE3;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}

.sp-accbox_edit_icon::before {
    color: #4D6EE3;
    margin: 0 !important;
}

.sp-details-box {
    border: 1px solid #E1E4EA;
    border-radius: 14px;
    padding: 20px 15px;
    margin-top: 30px;
}

.no_bor_data {
    border-top: 1px solid #E1E4EA;
}

.sp-details-box .sp-dashboard {
    text-align: center;
}

.field-label .label-title {
    color: #686C7B;
    font-weight: 300;

    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

}

.label-filled {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #000000;
    padding: 10px 0px 0px 0px;
}

.label-prf-img img {
    width: 50px;
}

.field-label-op .cross-ele,
.field-label-op .notapp-ele,
.field-label-op .icon-Tick {
    transform: scale(0.8);
    margin-right: 10px;
}

.field-label-op .icon-Tick {
    text-align: center;
}

.sp-dashboard {
    text-align: center;
}

.sp-dashboard {
    background: #FFFFFF;
    border: 1px solid var(--sp-border-clr);
    box-shadow: 0px 4px 44px 0px #0000000D;
    border-radius: 8px;
    text-align: left;
}

.sp-dashboard img {
    width: 200px;
    display: inline-block;
}

.close {
    border: 1px solid var(--sp-secondary-clr3);
    width: 34px;
    height: 34px;
    z-index: 22;
    background-color: #fff;
    position: absolute;
    top: 20px;
    color: #3D3D3D;
    display: flex;
    align-items: center;
    cursor: pointer;
}


/* my code */

.act-announce-sh {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px #0000000A;
    border-radius: 8px;
    text-align: left;
    border: 1px solid #E8EAF2
}

.sp-activ-bt1 .sp-lpdr {
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid #FFF;
    background: none;
}

.sp-activ-bt1 .sp-lpdr::before {
    font-size: 0.625em;
    line-height: 13px;
}

.dash-row,
.dashboard-top,
.dashboard-cont-right .sp-dashboard {
    margin-bottom: 20px;
    gap: 10px;
}

.dashboard-cont-right .sp-dashboard {
    max-width: 400px;
    width: 100%;
}

.sp-msg-sec-right .btn-search-bar {
    max-width: 904px;
    width: 100%;
    position: relative;
    margin-left: 10px;
}

.sp-dashboard-container {
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
}


/* importscreen */

.sp-top-box .sp-view-btn {
    padding: 10px 19px;
}


/* importscreen end */


/*  */

.dialog-model:after,
.dialog-model:before {
    /* content: ""; */
    /* 25.07.2024 - Remove hook in the model */
    /* position: absolute; */
    /* top: -7px; */
    /* right: calc(50% - 8px); */
    /* width: 0; */
    /* height: 0; */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    /* border-bottom: 8px solid #D9DCE6; */
    /* transition: opacity .4s ease; */
}

.dialog-model .field-label {
    text-align: left;
}

.custom-checkbox .text-blue-600 {
    border: 1px solid #E6E6E6;
}

.custom-checkbox [type=checkbox] {
    cursor: pointer;
}

.custom-checkbox [type=checkbox]:checked {
    background-color: var(--sp-theme-color2);
    border-color: var(--sp-theme-color2);
    cursor: pointer;
}

[type=checkbox]:focus,
[type=radio]:focus {
    box-shadow: none;
}


/*  */


/* timetable */

.activ-pop .sp-bottom-sticky {
    box-shadow: none;
    padding: 0 40px;
}

.activ-pop .sp-pop-header {
    height: auto;
}

.announcement-posts.newly-add {
    background: #FBFFFA;
}

.new-doc {
    position: relative;
}

.new-doc::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--sp-border-clr);
    left: 0;
    top: 0;
}

.text-styl .up-arr {
    margin-left: 5px;
}


/* timetable start*/

.week-day-box .dialog-model:after {
    left: -12px;
    transform: rotate(-90deg);
}

.text-styl .up-arr {
    margin-left: 5px;
}


/* timetable start*/

.week-day-box .dialog-model:after {
    left: -12px;
    transform: rotate(-90deg);
}

.select_field_point:hover {
    background-color: #F4F6FF;
}

/* activity post iamges*/


.more-images {
    width: 49.67%;
    background-color: rgba(0, 0, 0, 0.6);
    right: 0;
    height: 100%;
    color: #fff;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px !important;
    height: 18px;
    border-radius: 4px;
}

.more-text {
    font-size: 12px;
}

.modal-lightbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
}

.modal-lightbox.visible {
    opacity: 1;
    visibility: visible;
}

.img-container {
    width: calc(100% - 430px);
}

.img-container .slide-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #474A54;
    cursor: pointer;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.img-container .slide-arrow i:before {
    margin-right: 0;
}

.left-10 {
    left: 10px;
}

.right-10 {
    right: 10px;
}

.images-slide-box {
    width: 80%;
    max-width: 800px;
}


.profile-pic img {
    width: 32px;
    height: 32px;
}

.box-comment {
    width: calc(100% - 50px);
}



.comment-type {
    bottom: 0;
    padding: 15px 0px 0px 0px;
    background-color: #fff;
}

#create-template-popup h2 {
    padding: 40px 40px 20px;
}

#create-template-popup .sp-pop-header {
    padding: 40px;
    padding-top: 0;
}

.btn-dropdown-fieldval .activity-temp li {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.btn-dropdown-fieldval li.new-doc {
    padding: 13px 8px 8px;
}

.active_col .arrow {
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    width: 8px;
    height: 8px;
}

.body_heig {
    line-height: 1;
}

.borderbt {
    border-bottom: 1px solid #E8EAF2;
}

.come_border {
    border-bottom: 1px solid #E8EAF2;
    margin-top: 10px;
}

.bordertop {
    border-top: 1px solid #E8EAF2;
    padding-top: 15px;
    margin-top: 0px !important;
}

.text-clr1 {
    background-color: #F45B92;
}

.setgap {
    gap: 4px;
    flex-wrap: wrap;
    justify-content: center;
}

.body_heig .act-announce-inp input {
    font-size: 1rem !important;
    line-height: 1.5rem;
}

.halfplus-field {
    width: 67%;
}

.halfmin-field {
    width: 30%;
}

.text-decrt {
    padding: 10px;
    border-bottom: 1px solid var(--sp-border-clr);
}


/* ---------- image slider css   -------------- */

/* .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: -webkit-fill-available;
    max-width: 800px;
    margin: auto;
    max-height: 51px;
    min-height: 500px;
} */

.slider_style_cus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: calc(100vh - 4px);
    /* Take the full height of the device */
    max-width: 75%;
    margin: auto;

}

.slider_style_cus img {
    height: auto;
    width: auto;
    /* Maintain aspect ratio */
    /* padding-top: 3pc; */
    /* padding-bottom: 3pc; */
}

.slider_style_cus .left-arr {
    padding: 10px;
}

.slider_style_cus .right-arr {
    padding: 10px;
}

.single_image_view {
    /* display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    padding-right: 2vw;
    padding-left: 2vw; */
}

/* .img-wrapper {
    width: 96vw;
    height: calc(100vh - 8vh);
    overflow: hidden;
} */

.full-image {
    width: 100%;
    height: 100%;
}

.single_image_view img {
    width: 100%;
}

.slide-arrow {
    z-index: 10;
    cursor: pointer;
}

.left-arr,
.right-arr {
    background: none;
    border: none;
    outline: none;
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.images-slide-box {
    width: 100%;
    overflow: hidden;
}

.active_col img {
    width: 30px;
    /* Adjust size as needed */
}

/* --------------------------------------------------------- */
/* 
.act_program [type=text] {
    border-radius: unset !important;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-cmt {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
} */

/* .box-comment .icon-Message-Outline {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #007bff; 
    font-size: 20px;
    transition: color 0.3s;
}

.box-comment .icon-Message-Outline.disabled {
    color: #ccc; 
    cursor: not-allowed;
} */

.box-comment .fa-paper-plane {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    transition: color 0.3s;
}

.box-comment .fa-paper-plane.disabled {
    /* color: #ccc; */
    cursor: not-allowed;
    filter: opacity(0.5);
    /* top: 10px;
    right: 20px;
    position: absolute; */
    color: #FFFFFF;
    top: 7px;
    right: 9px;
}

.box-comment .fa-paper-plane {
    /* top: 10px;
    right: 20px; */
    top: 7px;
    right: 9px;
}

.act_like .clr_whi {
    color: #F14B4B;
}


/* 05-06 */
/* --------------------Activity------------------- */
.editorClassName {
    /* border: 1px solid #f1f1f1; */
    /* border-radius: 14px;
    min-height: 200px;
    padding: 10px;
    margin: 10px 0px; */
    font-size: 13px;
    font-weight: 500;
    line-height: normal!important;
    text-align: left;
    color: #424242;
}

.post-comments-inr {
    display: none;
}


.act_like i {
    margin-right: 5px;
}

.comments-box .comments {
    overflow-x: auto;
    height: 10pc;
    scrollbar-width: none;
}

/* 10-06 */
/* --------------------Calendar------------------- */
/* // 12-09-2024 start */
.rbc-toolbar {
    padding: 0px 15px !important;
}

/* // 12-09-2024 end */

/* -------26-07-2024--------------- */

/* .rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    border: unset !important;
} */

.ant-picker:hover {
    box-shadow: none;

}


.field-value-holder:focus-within {
    border-color: #4D6EE3;
    box-shadow: none;
    border: 1px solid #4D6EE3;
}

.field-value-holder.error {
    border: 1px solid red !important;
}

.field-value-holder.error:focus-within {
    border: 1px solid red !important;
}

.ant-picker-outlined:focus-within {
    padding-left: 15px;
    padding-right: 15px;
}

.ant-picker-outlined {
    background: unset;
    border-color: unset;
    box-shadow: none;
    /* border: unset !important; */
    border-width: unset !important;
    border-style: unset !important;
    border-color: 1px solid #D9DCE6 !important
}

/* :where(.css-dev-only-do-not-override-zg0ahe).ant-picker-outlined:hover {
    border-color: #4D6EE3 !important;
} */

/* :where(.css-zg0ahe).ant-picker-outlined:hover{
    border-color: unset !important; 
} */
.ant-picker-outlined :focus-visible {
    border: 1px solid #4D6EE3;
}

.ant-picker-outlined:hover {

    color: #1B252F;
}

.ant-picker:focus-visible {
    border: 1px solid #4D6EE3;
}

.ant-picker-outlined {
    width: 100% !important;
    height: 40px !important;
    border-style: hidden;
    background: transparent;
}

.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
    background: transparent;
    background-color: transparent;
    padding: 4px 14px 4px;
}

.ant-picker {
    padding: 4px 14px 4px;
}

.sp-field-input.error .field-value-holder input:focus {
    box-shadow: none;
}

/* .field-value-holder:focus-within .sp-field-input.error {
    border: none;
}

.field-value-holder:focus-within .field-value-holder {
    border: none;
} */

/* .sp-field-input.error .extra_bor_li {
    border: none !important;
} */

.ant-picker .ant-picker-suffix {
    color: #917474;
}

.flex.items-stretch.transition-all.duration-200.rounded-md.px-4.py-2.text-sm svg {
    color: #3D3D3D !important;
}

.flex.items-stretch.transition-all.duration-200.rounded-md.px-4.py-2.text-sm {
    width: 100% !important;
    justify-content: space-between !important;
    /* height: 40px; */
    white-space: nowrap;
}

/* ------------------------------------------------ */


/* 12.06 */
/* alterdy  */
.sp-field-input.error {
    border: 1px solid red;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #1b252f;
}

/* .event_calc_cus_st [type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
    border-radius: 8px !important;
    color: #1B252F;
    font-size: 13px !important;
    line-height: 18.2px !important;
} */

.ant-picker-input>input::placeholder {
    color: rgb(112, 112, 112) !important;
    font-size: 0.8125em !important;
    font-weight: 500 !important;
    line-height: 18.2px !important
}

.input-placeholder1::placeholder {
    color: rgb(241, 0, 0) !important;
    font-size: 0.8125em !important;
    font-weight: 500 !important;
    line-height: 18.2px !important
}

/* .selected-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
} */

.selected-item svg {
    position: absolute;
    right: 12px;
}

.custamize_color_place input::placeholder {
    color: #A9AFC3;
    font-weight: 500;
    font-size: 13px;
}

.custamize_color_place input {
    color: #1B252F;
    font-weight: 600;
    font-size: 13px;
}

.ant-picker-input input::placeholder {
    color: #1B252F !important;
    font-weight: 500;
    font-size: 13px;
}

/* Style the DatePicker input text */
.ant-picker-input input {
    color: #1B252F;
    font-weight: 500;
    font-size: 13px !important;
}

.ant-picker .ant-picker-input-placeholder>input {
    color: #1B252F !important;
}

.drop_move_cus .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow {
    min-width: 125px !important;
    box-shadow: 0px 6px 14px 0px #0000001A;

}

.drop_move_cus .divide-y.divide-gray-100.rounded.shadow {
    transform: translate(45px, 167px) !important;
    border: 1px solid #D9DCE6;
    box-shadow: 0px 6px 14px 0px #0000001A;
    border-radius: 6px;
}

.drop_move_cus ul li i {
    padding: 5px 5px;
}

.drop_move_cus ul {
    width: 175px;
}

.focus\:ring-4:focus {
    --tw-ring-shadow: unset !important;
}



.back_white_cus {
    background-color: white;
    width: 80%;
    position: absolute;
    top: 5px !important;
    min-width: unset !important;
    padding: 5px;
    border-radius: 6px;
    padding: 10px 10px !important;
    box-shadow: 0px 6px 14px 0px #0000001A;
    border: 1px solid #D9DCE6;
    border-radius: 6px;
}

#dropdownSearch ul li {
    margin: 5px 0px;
}

#dropdownSearch ul li label {
    font-size: 13px;
    font-weight: 600;
    line-height: 15.73px;
    text-align: left;
    color: #1B252F;
    padding: 10px 5px;
    height: 36px;
}


#dropdownSearch ul li input {
    /* border: 1px solid #b0b1b3; */
    border: 1.5px solid #D9DCE6;
    border-radius: 5px;
    font-size: 14px;
}

#dropdownSearch ul li input:focus {
    box-shadow: none !important;
}

#dropdownSearch ul li input:checked {
    border: 1px solid transparent;

}

/* .pad_15 {
    padding: 10px 5px;
} */

/* .cus_pro .sp-selected-bt {
    padding: 6px 8px;
    color: #1B252F;
    font-size: 13px;
} */
.cus_pro .sp-selected-bt {
    padding: 5px;
    color: #1B252F;
    font-size: 11px;
    font-weight: 600;
    width: 28%;
}

.pro_wid_style {
    width: 76%;
}

.pro_more_text {
    padding: 5px;
    color: #1B252F;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px !important;
    position: absolute;
    top: 9px;
    right: 24px;
}

.btn-search-bar {
    /* Base styles */
    padding: 0px 8px;
    /* border: 1px solid #ccc; */
    transition: border-color 0.3s ease;
}

.btn-search-bar.focused {
    /* Styles when div is focused */
    border-color: #007bff;
    /* Example border color on focus */
}

.program_checked {
    background-color: #f3f4f6;
}

.flex.items-center.pad_15.rounded input[type=checkbox] {
    margin-left: 15px;
}

/* / 25-06 / */
.data-loader {
    transform: translateY(100%);
    text-align: center;
    width: 100%;
}

.data-loader span {
    color: #6B6F8C;
    font-size: 13px;
    font-weight: 500;
}

.col_red {
    color: #F14B4B;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;

}

.set-bord.left-arr {
    border: 1px solid #D9DCE6;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.set-bord.right-arr {
    border: 1px solid #D9DCE6;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/*  */
.sp-header .first_list_mar_cus {
    margin-left: 10px;
}

.sp-right-panel .top_bar_cus {
    /* padding: 1.25em 1.2em; */
    padding: 15px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 45px;
}

.exta_pad_10 {
    padding-top: 20px;
    padding-bottom: 15px;
}

/* .sp-right-panel .top_bar_cus .ft-wt600.flexc {
    padding-left: 24px;
    font-size: 15px;
} */

.extra_pad_men {
    padding-left: 13px;
    padding-right: 45px;
}

.extra_side_padcus {
    padding: 0px 14px !important;
}

.sp-head.sp_sticky li {
    font-weight: 600;
}

.drop_ic_cus i {
    position: absolute;
    right: 10px;
    top: 13px;
    color: #3D3D3D;
}

.sp-field-input.error:focus {
    border: 1px solid red;
    box-shadow: none;
    border-color: inherit;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline-offset: 0px !important;
}


:where(.css-dev-only-do-not-override-zg0ahe).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #485CF0;
}

:where(.css-dev-only-do-not-override-zg0ahe).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #485CF0;
}

.extra_flex_cusname {
    flex: 0 0 15% !important;
}

.extra_flex2_cusname {
    flex: 0 0 13% !important;
}

.padr_16 {
    padding-right: 16px;
}

@media screen and (max-width:992px) {
    .media_style_pop {
        transform: translate(950px, 155px) !important;
    }

    .sp-table-body {
        height: 470px !important;
    }


}

@media screen and (max-width:1400px) {
    .edit-program {
        left: 400px !important;
        top: 53px !important;
    }

    /* .coment_footer {
        width: 22% !important;
    } */
}

@media screen and (max-width:1366px) {
    .coment_footer {
        width: 300px !important;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.excla_div img {
    padding-right: 10px;
}

.invite_cus_div {
    width: 100%;
    white-space: nowrap;
    margin-left: 10px;
    box-shadow: 0 2px 5px 0 #0000000d !important;

}

.invite_cus_div span {
    /* padding-left: 6px; */
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #1B252F;
}

.invite_cus_div .sp_mbtn {
    height: 40px;
    width: 100%;
}

.but_foc_cus .field-value-holder button:focus {
    outline: none;
    box-shadow: 0 0 0 1px #4D6EE3;
    border-radius: 8px;
}

.field-value-holder input:focus {
    outline: none;
    box-shadow: 0 0 0 1px #4D6EE3;
}


.update_list_det {
    color: #000000;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
}

.update_list_det1 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #000000;
}

.he_fix_cus {
    height: 100vh;
}

.pos_stu_cus {
    position: relative;
}

.pos_abo_stu {
    position: absolute;
}

.mar_ri_name {
    margin-right: 16px;
    font-size: 13px;
}

/* 12.09 */

.but_new_cu .cancel_div_di {
    padding: 10.32px 22px;
}

.but_new_cu .cancel_div_di:hover {
    color: #1B252F;
}

.but_new_cu .save_cus_di {
    padding: 11.32px 22.92px;
}

.bor_bot_stucus {
    border-bottom: 1px solid var(--sp-border-clr);
}

.font_13 {
    font-size: 13px;
}

.pad_30_ex {
    padding-left: 30px;
}

.pad_ic_ri {
    padding-left: 25px;
    font-size: 15px;
}

.date_pic_img .ant-picker-suffix {
    display: none;
}

.date_pic_img .ant-picker-input>input {
    /* background-image: url('../../../pages/school/images/Calendar.svg'); */
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: right 0px center;
    padding-right: 28px;
}

/* ----------------------------- */
.sp-list-view.selected {
    position: relative;
}

.sp-list-view.selected::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 7%;
    width: 95%;
    height: 2px;
    background-color: var(--sp-blue-clr);
}

.but_new_cu .cancel_div_di {
    padding: 10.32px 22px;
}

.but_new_cu .save_cus_di {
    padding: 11.32px 22.92px;
}

.date_pic_img .ant-picker-clear {
    display: none;
}

/* -----student list ------------ */
.click-popup {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cus_div_inputas input {
    margin-left: 0px ! important;
    margin-right: 15px;
}

.flex.items-center.mb-4.custom-checkbox.mt-5 label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    color: #0A090B;
}

.flex.items-center.mb-4.custom-checkbox.mt-5 h3 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: #28243F;
}

.extra_bor_clr {
    width: 100%;
    border: 1px solid var(--sp-border-menu);
    box-shadow: 0px 2px 5px 0px #0000000D;
    position: relative;
    border-radius: 8px;
    height: 40px;
}


.extra_bor_clr.sp-field-input.error:focus {
    border: 1px solid red !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.prl_1 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.cus_drop_siz .divide-gray-100.rounded.shadow {
    width: 145px;
    height: 16pc;
    overflow: auto;
}

.sp-accbox1 img {
    /* width: 91px; */
    width: unset !important;
    height: 91px;
}

.sub_select:disabled {
    cursor: not-allowed !important;
    filter: opacity(0.5);
}

.set-bord.left-arr:hover {
    color: #373a3c;
    background-color: #F3F3F7;
    border-color: var(--sp-br-clr);
}

.set-bord.right-arr:hover {
    color: #373a3c;
    background-color: #F3F3F7;
    border-color: var(--sp-br-clr);
}

.sel-days.set-bord {
    color: #373a3c;
    border: 1px solid #D9DCE6;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    line-height: 1px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    text-align: left;

}

.side_im_cu p {
    padding-left: 15px;
}

.popup-modal-container {
    z-index: 9999 !important;
}

.activity_drop .z-10.w-fit.divide-y.divide-gray-100.rounded {
    width: 165px !important;
    left: 19px !important;
    border: 1px solid #D9DCE6;
    box-shadow: 0px 6px 14px 0px #0000001A;
}

.extracss_load {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(35vh - 0px);
}

.extracss_load p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #6B6F8C;
    padding-top: 1pc;
}

.font_in_text input::placeholder {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #0606068a;
}

.font_in_text input {
    font-family: Inter;
    font-size: 18px !important;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #000000;
    padding-bottom: 0px;
}

/* .dis_chek_cus :checked {
    cursor: not-allowed!important;
} */

/* .custom-checkbox [type=checkbox]:checked */

.dis_chek_cus input:disabled {
    cursor: not-allowed !important;
    filter: opacity(0.5);
}

.extracss_load span {
    width: 3px !important;
    height: 9px !important;
}

.defult-date-header {
    background-color: #FAFBFC;
    color: #1B252F;
}


.active-date-header {
    background-color: #ECF1FF;
    color: #4D6EE3;
    border-top: 1px solid #90A4F3;
    border-right: 1px solid #90A4F3 !important;
    border-left: 1px solid #90A4F3;
}

.active-date-data {
    background-color: #FFFFFF;
    border-right: 1px solid #90A4F3 !important;
    border-left: 1px solid #90A4F3;
}

.active-last-date-data {
    border-bottom: 1px solid #90A4F3 !important;
}

.next-date-data {
    background-color: #FAFBFC;
}

.im_sm_siz {
    position: absolute;
    top: -10px;
    right: 5px;
}

.im_sm_siz span {
    font-size: 20px;
}

.sp-accbox1 {
    padding-right: 15px;
}

.act_program .activity_drop button span {
    margin-right: 0px;
}

.activity_drop {
    border-radius: 20px;
    background: #F0F0F0;
    padding: 4px 10px;
}

/* .template_div_cus .z-10.w-fit.divide-y.divide-gray-100.rounded {
    transform: translate(144px, 308px) !important;
} */
.template_div_cus ul {
    /* margin: 5px 0px; */
    /* padding-left: 8px; */
}

.template_div_cus ul li button {
    padding: 5px 15px !important;
}

.template_div_cus ul li button:hover {
    background-color: #F3F3F7;
    padding: 5px 15px;
}

.template_div_cus ul li button .dd_select {
    background-color: #F1F3F9 !important;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px 15px;
}

.template_div_cus ul li .dd_select {
    background-color: #F1F3F9 !important;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px 15px;
}

.seqlect_div_cus .z-10.w-fit.divide-y.divide-gray-100.rounded {
    min-width: 150px !important;
    /* transform: translate(85px, 112px) !important; */
    left: 19px !important;
    top: -6px !important;
    border: 1px solid #D9DCE6;
    box-shadow: 0px 6px 14px 0px #0000001A;
}


.activit_pa_in [type='text']:focus {
    --tw-ring-color: transparent;
}

.bor_rad img {
    border-radius: 30px !important;

}

.images-slide-box.flexc.alignC {
    position: relative;
    width: 100%;
    max-width: 500px;
}

.images-slide-box.flexc.alignC img {
    display: block;
    width: 100%;
    height: auto;
    /* object-fit: cover; */
}

.editorClassName.rdw-editor-main {
    height: 120px;
    padding-top: 10px;
    scrollbar-width: none;
}

/* -----02-08------- */

.commentEditorClassName {
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #424242;
}

.commentEditorClassName.rdw-editor-main {
    height: 40px;
    scrollbar-width: none;
}

.activity_foot_div {
    margin-top: 15px;
    padding: 20px;
    border-top: 1px solid #E8EAF2;
    border-radius: 8px 8px 12px 12px;
}

.right-arr:disabled {
    cursor: not-allowed !important;
    filter: opacity(0.5);
}

/*  */

.exta_pad_10 {
    /* padding-right: 45px; */
    padding: 15px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 45px;
}

.clas_plu_icon i {
    font-size: 11px;
}

.ant-picker-dropdown {
    z-index: 999999;
}

.size_extra_hei_single {
    height: 18px;
    width: 18px;
}

.size_div_pad {
    padding-left: 3px;
    /* text-transform: capitalize; */
}

.fir_size_div {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #333333;
    padding-right: 3px;
}

.sec_size_div {
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #505D6F;
}

.nodata_border {
    border-top: 1px solid #E8EAF2;
    padding: 1.25em 0;
    margin: 0px;
    text-transform: capitalize;
}

.icon_clr_ser i::before {
    color: #4d505e;
}

.sp-menubar-clslist.flexc ul {
    gap: 3px;
}

.noti_sdi {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 3px;
}

.noti_sdi p {
    white-space: nowrap;
}

.noti_tot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-right: 1pc; */
}

.res_tot_div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2pc;
    box-sizing: border-box;
    z-index: 9999;
}


.createN.space_icon_pad {
    padding: 10px 19.922px;
}

.create-post img {
    max-width: 48px !important;
    border-radius: 8px;
    margin-right: 10px;
}

/* above class also styles changed */
/* // sakthivel 03-04-05 JULY 2024 start // */

.dash-titles {
    color: #1C1C1C;
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.program-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #000B23;
    margin-left: 5px;
}

.program-name {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    letter-spacing: -0.005em;
    text-align: right;
}

.program-time {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #1C1C1C;
}

.att-count {
    font-family: Inter;
    font-size: 20px;
    font-weight: 900;
    line-height: 32px;
    text-align: left;
}

.dash-row1-box span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #0F172A;
}

.sub-title {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #1B252F;
}

.hol-title {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #1B252F !important;
}

.activity-title {
    font-family: Inter;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #2E2E2E;
}

.actiity-content {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #2E2E2E;
}

.activity-time {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #8C8C8C;
}

.activity-card {
    justify-content: flex-start !important;
}

.activity-main-card {
    width: 470px;
    height: 270px !important;
}

.programe-card {
    justify-content: flex-start !important;
}

/* .activity-card div {
    align-items: unset !important;
} */

.week-btn {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #1B252F;
}

.glo-showdow {
    box-shadow: 0px 2px 5px 0px #0000000D !important;
}

@media screen and (max-width:1552px) {
    .dash-row3-box {
        max-width: 190px !important;
        height: 160px;
        width: 100%;
        position: relative;
        padding: 15px;
        text-align: left;
    }
}

/* // sakthivel 03-04-05 JULY 2024 end // */

/*  */
.annoce-poster-btm.flexc.mt-10 {
    border-top: 1px solid #E8EAF2;
    padding-top: 15px;
    margin-top: 15px !important;
    padding-bottom: 7px;
    gap: 25px;
}

.message-box .annoce-poster-btm.flexc.mt-10 {
    border-top: unset !important;
}

.post_view_heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #1B252F;
    text-transform: capitalize;
    padding-bottom: 10px;
}

.content_style_view {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    /* line-height: normal; */
    line-height: 24px;
    text-align: left;
    color: #1B252F;
    /* padding-top: 8px; */
}

.seemore_style_view {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    color: #1B252F;
}

.marg_view_style {
    margin-top: 8px;
    margin-bottom: 8px;
}

.user_name_style {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.73px;
    text-align: left;
    color: #1B252F;
}

.sub_name_style {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    color: #1B252F;
}

.date_name_style {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #686C7B;
}

.annonce-pers-left-cont p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    color: #686C7B;
}

.food_sleep_saveic {
    padding-top: 15px;
}

.calender_cre_can .createN {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.99px;
    text-align: left;

}

.calender_cre_can .cancel-btn {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.99px;
    text-align: left;
    padding: 10px 15px;
}


.food_head_style {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #27272B;
}

.food_sleap_lab .field-label label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #1B252F;
}

.food_sleap_lab .field-label {
    margin-top: 23px;
}

.fixed-height-textarea {
    height: 68px !important;
}

.css-2loup5 {
    border-radius: 12px !important;
}

/*  */

.coment_footer {
    position: fixed;
    width: 381px;
    bottom: 0px;
    padding: 10px 0px;
    background-color: white;
}

.drop_list_full .drop_move_cus .divide-y.divide-gray-100.rounded.shadow {
    transform: translate(45px, 160px) !important;
}

.activity_page .css-zzms1-MuiSnackbar-root {
    left: 57%;
}

.document_class p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #1B252F;
    padding-left: 6px;
}

.bell_ic_acti i {
    --fa-rotate-angle: 320deg;
    font-size: 17px;
    padding: 10px;
    cursor: pointer;
}

.bell_ic_acti i:hover {
    color: #4D6EE3;
    padding: 10px;
}

.back_bel_i:hover {
    background-color: #485cf029;
    border-radius: 6px;
}

.image_hov_blur img:hover {
    filter: none;
    background-color: #485cf029;
    border-radius: 6px;
    padding: 7px 7px;
    cursor: pointer;
}

.image_hov_blur img {
    filter: grayscale(1);
    padding: 7px 7px;
    width: 100%;
    cursor: pointer;
}

/* Styles for the image container and arrows */
.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Add other styles as needed */
}

.left,
.right {
    top: 50%;
    transform: translateY(-50%);
}

.slide-container {
    width: 100%;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
}

.slide-image {
    max-width: 100%;
    height: auto;
    display: block;
}

.portrait-image {
    max-height: 100%;
    width: auto;
    display: block;
    margin: 0 auto;
}


/* -----------10-07-------------------- */
/* ----attendance-details------- */
.sp-details-box .attendance-details {
    text-align: -webkit-center;
    /* margin-top: 14px; */
}

.sp-details-box .attendance-details .total-present {
    font-family: Inter;
    font-size: 33px;
    font-weight: 400;
    line-height: 39.94px;
    letter-spacing: -0.08em;
    color: #000000;
}

.sp-details-box .attendance-details p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #686C7B;
}


/*  */

.field-label-op .gap-cus-img {
    gap: 10px;
}

.edit-program {
    box-shadow: 0px 6px 14px 0px #0000001A;
    border: 1px solid #D9DCE6;
    padding: 5px;
    cursor: pointer;
}

.edit-program .cust-program-icons {
    display: flex;
    padding: 10px;
    color: #1B252F;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    align-items: center;

}

.edit-program .cust-program-icons:hover {
    background: #F3F3F7;
    border-radius: 6px;

}

.active-Icon {
    background: #F3F3F7;
    cursor: pointer;
    border-radius: 6px;
}

.program-icon-style {
    padding-left: 9px;
    padding-top: 3px;
}

.program-icon-style:hover {
    background: #F3F3F7;
    cursor: pointer;
    border-radius: 6px;
    padding-left: 9px;
    padding-top: 3px;
}

.pos_abou_pro {
    position: relative;
}

.svg_hide_drop svg {
    display: none;
}

.pos_fix_edit .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow {
    right: 10px !important;
    top: 26px !important;
    max-width: 170px !important;
    transform: unset !important;
    left: unset !important;
    border: 1px solid #d9dce6;
    box-shadow: 0 6px 14px 0 #0000001a;
    cursor: pointer;
    padding: 0px;
    border-radius: .5rem;
}

.pos_fix_edit_student .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow {
    right: unset !important;
    top: 0px !important;
    max-width: 170px !important;
    min-width: 140px !important;
    left: 65px !important;
    border: 1px solid #d9dce6;
    box-shadow: 0 6px 14px 0 #0000001a;
    cursor: pointer;
    padding: 0px;
    border-radius: .5rem;
    z-index: 99;
}

.pos_padd_dis .flex.items-stretch.transition-all.duration-200.rounded-md.px-4.py-2.text-sm {
    padding: 0px;
}

.active_ic_more {
    padding: 5px 5px 0px 5px;
    /* margin: 0px; */
}

.active_ic_more:hover {
    background-color: #F3F3F7;
    padding: 5px 5px 0px 5px;
    border-radius: 5px;
}

.active_ic_more:before {
    margin: 0;
    height: 0px;
    line-height: 0px;
}

.stude_li_edi .flex.items-stretch.transition-all.duration-200.rounded-md.px-4.py-2.text-sm {
    padding: 0px;
}

.activity_page .sp-right-panel {
    width: calc(100% - 16.67em);
    margin-left: 17.159em;
    position: relative;
}

.z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul {
    padding: .25rem;
}

.z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul li button {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 13px;
    font-weight: 400;
    color: #1B252F;
}

.z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul li button img {
    margin-right: 5px;
}

.event_drop_style .z-10.w-fit.divide-y.divide-gray-100.rounded.shadow ul li button {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 13px;
    font-weight: 400;
    color: #1B252F;
    height: 36px;
}

.back_clr_white {
    background-color: white;
}

.pad_bot_6 {
    padding-bottom: 5pc !important;
}

.mes_onclick[class^="icon-"]:before {
    color: #F14B4B;
}

.react-slideshow-wrapper.slide .active {
    display: flex;
    align-items: center;
}

.like_com_style {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;

}

.doc_pre {
    padding: 7px;
    border-radius: 8px;
    background: #F2F4F8;
    /* width: 100%; */
    position: relative;
    margin-right: 10px;
}

.noti_left {
    background: #FB6060;
    /* padding: 3px 3px;  */
    border-radius: 4px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.noti_left p {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    padding: 0px;

}

/* .activity-container .small-tag {
    background-color: #8FA7FF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;
    color: #fff;
    border-radius: 10px;
} */


.message-box .annonce-pers-left.small-tag {
    background-color: #8FA7FF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;
    color: #fff;
    border-radius: 10px;
}

/* .message-box .comments-box .comment-user {
    background-color: #E58080;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;
    color: #fff;
    border-radius: 10px;
} */

/* .annonce-pers-left .small-tag {
    background-color: #8FA7FF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;
    color: #fff;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;

} */



.s_div {
    border-radius: 10px;
    background: #E58080;
    padding: 13px 17px;
    color: #FFFFFF;
}

/* / 15.07.2024 / */


.notifyStyle {
    position: absolute;
    left: 274.8px;
    bottom: 100px;
    margin: 0px;
    max-width: 450px;
    min-width: 450px;
    border: 1px solid #E6E6E6;
    box-shadow: -6px 0px 12px 4px #00000008;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 9;
}


.notification_header {
    padding: 16px 25px;
    /* background: #FFFFFF; */
    /* border-bottom: 1px solid #E8EAF2; */
}

.notification_header .noti_flex {
    display: flex;
    justify-content: space-between;
}

.notification_header .noti_flex .noti_dis_flex1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notification_header .noti_flex .noti_dis_flex {
    display: flex;
    align-items: center;
    gap: 20px;
}

.notification_header .noti_flex .noti_dis_flex img {
    cursor: pointer;
}

.notification_header .noti_flex h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    color: #555555;
}

.notification_header .noti_flex h4 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: right;
    color: #53646E;
}


.notification_header .noti_flex p {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
    background: #FB6060;
    padding: 3px 5px;
    border-radius: 4px;
    color: #FFFFFF;
}

.notification_body {
    height: 570px;
    overflow: auto;
    scrollbar-width: none;
}



.notifyStyle .dis_fle_body {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notifyStyle .noti_dot i {
    font-size: 5px;
    color: #FB6060;
    float: right;
    padding-top: 25px;
}

.notifyStyle .notification_body h3 {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    color: #000000;
}

.notifyStyle .notification_body p span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #313131;
}

.full_noti_body {
    padding: 5px 0px;
    width: 100%;
    display: flex;
}

.full_body_notifi {
    position: relative;
    padding: 9px 20px;
    /* box-shadow: 0 1px 0 0 #E8EAF2 inset; */
    /* border-bottom: 1px solid #dbdde3; */
}

.full_body_notifi::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    /* Position the pseudo-element at the bottom of the parent */
    width: 90%;
    /* Set the width to 90% */
    height: 1px;
    /* Set the height to 1px to mimic the border */
    background-color: #E8EAF2;
    /* Set the border color */
    transform: translateX(-50%);
    /* Center the pseudo-element */
}

.full_body_notifi.active_cls {
    background-color: #F8F9FD;
    /* cursor: pointer; */
}

/* .full_body_notifi.active_cls:hover {
    background-color: #001052;
} */
.day_notify {
    position: absolute;
    top: 10px;
    right: 25px;
}

.day_notify p {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #A0A0A0;
}

.colnot2 {
    width: 13%;
}

.colnot1 {
    width: 3%;
}

.colnot7 {
    width: 84%;
}

.noti_footer {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px 15px;
}

.noti_footer p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #263238;
    width: 60%;
}

@media screen and (max-width:1400px) {
    .notifyStyle {
        left: 240.8px !important;
    }

    .notification_body {
        height: 400px !important;
    }

    .full_body_notifi {
        position: relative;
        padding: 9px 25px;
    }

}

.tooltip-active {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-tooltip {
    z-index: 999 !important;
}

.name_first {
    border-radius: 7px;
    background: #7B81CC;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    width: 30px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.top_name_single {
    border-radius: 7px;
    background: #7B81CC;
    padding: 8px 10px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
}

.purpile_clr {
    background: #7B81CC;
}



.extra_rose {
    background: #E58080;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 7px;
    width: 30px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.rose_clor {
    background: #E58080;
}



.com_down_class {
    border-radius: 7px;
    background: #E58080;
    font-size: 13px;
    font-weight: 600;
    color: #FFFFFF;
    margin-right: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 30px;
    height: 28px;
}

.stude_li_edi .program_tit {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
}

.close_activity {
    border: 0px solid #EFF1F8;
    width: 34px;
    height: 34px;
    justify-content: center;
    z-index: 22;
    position: relative;
    background-color: #EFF1F8;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 30px;
    color: #000000;
    font-size: 10px;
}

@media screen and (max-width:1373px) {
    .sp-right-panel .top_bar_cus .ft-wt600.flexc {
        padding-left: 23px;
        font-size: 15px;
    }

    /* .sp-header .first_list_mar_cus {
        margin-left: 2px;
    } */
}

.icon-Dropdown_Close:before {
    font-size: 17px;
    font-weight: 500;
}

/*  */
.public-DraftStyleDefault-block {
    margin: 0em 0 !important;
}

.extra_fl_comment {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.snack_total {
    z-index: 99999 !important;
}

.react-slideshow-container {
    height: 100vh;
}

.slide-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.each-fade {
    display: flex;
    align-items: center;
    justify-content: center;
}

.each-fade img {
    width: 100%;
    height: 100vh;
    object-fit: fill;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap>div {
    display: flex !important;
    justify-content: space-around;
}

.each-fade[aria-hidden="false"] {
    align-items: center;
    /* Center the slide when aria-hidden is false */
}

/* ------ */

.slide-container {
    width: 100vh;
    /* height: 100vh;  */
    margin: auto;
}

.each-slide {
    display: flex;
    justify-content: center;
    align-items: center !important;
    vertical-align: middle;
    height: 100vh;
}

.slide-content {
    /* width: 100%;
    height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.slide-content span {
    /* color: #fff;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px; */
    vertical-align: middle;
}

/* ---------------------------- */

/* 24-07-2024 */
.feed_comr .coment_box_feed textarea {
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    font-weight: 400;
    /* height: 40px !important; */
    padding: 7px 16px;
    width: 100%;
    padding-right: 3pc;
    box-sizing: border-box;
    white-space: normal;
    scrollbar-width: none;
}

.feed_comr .coment_box_feed textarea::placeholder {
    /* text-align: center; */
    color: #6C757D;
}

/* .coment_icon_box {
    position: absolute;
    top: 5px;
    right: 15px;
    background: #4D6EE3;
    padding: 15px 16px;
    border-radius: 8px;
} */
.coment_icon_box {
    position: absolute;
    top: 5px;
    right: 15px;
    background: #4D6EE3;
    /* padding: 6px 6px; */
    border-radius: 8px;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.coment_icon_box.disabled {
    filter: opacity(0.5);
    cursor: default;
}

.pro_staf_name {
    background-color: var(--sp-tag-color);
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 2px;
}

.mar_top_roll {
    margin-top: 10px;
}

.roll_mar_top {
    margin-top: 23px;
}

.mar_ful_permison button {
    margin: 2px;
}

.mar_ful_permison {
    margin-top: 10px;
}

.att-popup {
    display: flex;
    justify-content: space-around;
}

.att-popup .att-cancel {
    margin: unset !important;
    border: 1px solid #D7D9DD !important;
}


.name_tag_side p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
}

.come_box_div {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    /* line-height: normal; */
    text-align: left;
    color: #1B252F;
    padding-top: 10px;
    word-break: break-all;
    overflow-wrap: break-word;
}

.rbc-toolbar button:hover {
    color: #373a3c !important;
    background-color: #F3F3F7 !important;
    border-color: var(--sp-br-clr) !important;
}

.styles-module_tooltip__mnnfp {
    font-size: 11px !important;
    font-weight: 600;
    line-height: 1.5;
}

.pop_main_sloep .field-value-holder .divide-gray-100.rounded.shadow {
    height: 14pc;
    overflow: auto;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
    background-color: #FFFFFF !important;
    background-image: none;
    box-shadow: none !important;
    /* border: 1px solid #f3f3f7 !important; */
}

.dis_pro_flex {
    display: flex;
    align-items: center;
}


.small-tag-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: #fff;
    border-radius: 4px;
}


.acti_post_av {
    border-radius: 7px;
    width: 30px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
}

.dashbord_pro_style {
    border-radius: 7px;
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
}

.act_create_post_avat {
    border-radius: 7px;
    padding: 13px 16px;
    width: 38px;
    height: 38px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
}

.cre_post_border {
    border: 1px solid #4D6EE3
}


/* 09.09.2024 */
.btn-search-bar.flexc.glo-showdow input {
    padding-right: 1.5pc;
    height: -webkit-fill-available;
}

.upcoming_dte_style {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: right;
    color: #1B252F;
}

.m-0 {
    margin: 0 !important;
}

.scl-prg .extra_progr_tic button .tick-ele:after {
    border-color: #4d6ee3;
    top: 2px;
    left: 5px;
}

.extra_progr_tic .label-container input:checked~.tick-ele {
    background-color: white !important;
}

.extra_progr_tic .tick-ele:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(44deg);
    position: absolute;
    top: 3px;
    left: 5px;
    display: none;
}

.align_cen_progr {
    display: flex;
    align-items: center;
}

.extra_progr_tic .size-cont1.program-size.flex-prop .last_div {
    display: block;
}

.extra_progr_tic .label-container {
    margin-right: 5px;
}

.extra_progr_tic .program_name {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;

}

.scl-prg .extra_progr_tic button .tick-ele {
    border: 1.08px solid #C7CDDE !important;
}

.extra_progr_tic .scl-prg button {
    border-radius: 6px;
}

.school_size_cus .label-container .circle-ele:after {
    content: "";
    bottom: 5px;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #4d6ee3;
    position: absolute;
}

.scool_size_par {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
}

.school_dis_p {
    display: flex;
    align-items: center;
}

.cur_norml button {
    cursor: context-menu;
}

.onbording_main {
    padding-inline: 25px;
}

.onbording_main h3 {
    text-align: start;
    margin-block: 0 15px;
}

.onbording_main .logo_sec img {
    background: #8D99F6;
    padding: 6px;
    border-radius: 10px;

}

.onbording_main .logo_sec {
    width: 50px;
    height: 50px;
    border: 1.9px dashed #59758A;
    padding: 3px;
    border-radius: 10px;
}

.onbording_main .total_div_change {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.onbording_main .totl_inside_one {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.onbording_main .totl_inside_two {
    display: flex;
    gap: 10px;
}

.onbording_main p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
    color: #000000;
}

.onbording_main .totl_inside_two .change_logo_onbor {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
    color: #485CF0;
}

.onbording_main .totl_inside_two .remove_logo_onbor {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #F14B4B;
}

.onbording_main .onbor_div_input input {
    margin: 10px 0 0 0 !important;
}

.padbl_30 {
    padding-block: 5px 30px;
}

.onbor_div_input .field-label {
    margin-top: 25px;
}

.padt_35 {
    padding-top: 35px;
}

.import_cust .max-w-xs {
    max-width: fit-content;
}

.logo-component {
    border-radius: 7px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
}

.preview-logo-component {
    border-radius: 7px;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
}

.preview-parentlogo-component {
    border-radius: 7px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
}

/* 11-09-2024 Start*/
.ant-picker-cell-today .ant-picker-cell-inner {
    border: none;
    background: none !important;
    color: black;
}

/* 11-09-2024 End*/


/* 12.09.2024 */
.my_pro_div li.selected:before,
.my_pro_div li:hover:before {
    background: #F3F3F7;
    box-shadow: unset;
    border: 0;
}

.my_pro_div:hover:before {
    background-color: #F3F3F7 !important;
    box-shadow: unset;
    border: 0;
}

.my_pro_div button:hover {
    background-color: transparent !important;
}

.clr_black {
    color: #000000;
}

.fs-13 {
    font-size: 13px;
}

.dis_on_board_cener {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/* 12-09-2024  */
.error.pos_abo_stu {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.logo_top_display {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.logo_top_display .w-10 {
    width: 30px;
}

.logo_top_display .h-10 {
    height: 30px;
}

.program_school_pos {
    position: absolute;
    top: 4pc;
    right: 20%;
}

.check_box_schoolinfo {
    position: absolute;
    right: 20%;
    top: 9pc;
}

.highlight_post {
    background-color: #FBFFFA;
}

/* -----23-09 akash -------*/
.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.mt-12 {
    margin-top: 12px !important;
}

/* ---------------------------- */


/* 26.09.24 akash */
.nodata_center_pos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.dash_page .nodata_center_pos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 155px;
    text-align: center;
}



.nodata_center_pos h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    padding-bottom: 10px;
    color: #000000;
}

.nodata_center_pos p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding-bottom: 15px;
    width: 550px;
    color: #797979;
}

/*  student img*/

.nodata_Student_style {
    text-align: -webkit-center;
}

.nodata_Student_style img {
    width: 100px;
    max-width: 100px;
    padding-bottom: 15px;
}

/*  activity img*/



.nodata_activity_style {
    text-align: -webkit-center;
}

.nodata_activity_style img {
    width: 367px;
    max-width: 367px;
    padding-bottom: 15px;
}


/* / maintanence  / */
.nodata_maintanence_style {
    text-align: -webkit-center;
}

.nodata_maintanence_style img {
    width: 367px;
    max-width: 367px;
    padding-bottom: 15px;
}

.nodata_maintanence_style p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #263238;
}

.nodata_maintanence_style h3 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    color: #263238;
}

/* / oops  / */
.nodata_oops_style {
    text-align: -webkit-center;
}

.nodata_oops_style img {
    width: 392px;
    max-width: 392px;
    padding-bottom: 15px;
}

.nodata_oops_style h3 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    color: #263238;
}

.nodata_oops_style p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #263238;
}

/* / search  / */
.nodata_search_style {
    text-align: -webkit-center;
}

.nodata_search_style img {
    width: 127px;
    max-width: 127px;
    padding-bottom: 15px;
}

/* / dash_nonoti  / */
.nodata_dashnono_style {
    text-align: -webkit-center;
}

.nodata_dashnono_style img {
    width: 142px;
    max-width: 142px;
    padding-bottom: 15px;
}

/* / no event  / */
.nodata_dashevent_style {
    text-align: -webkit-center;
}

.nodata_dashevent_style img {
    width: 100px;
    max-width: 100px;
    padding-bottom: 15px;
}

.info_round_btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #E7ECFF;
    color: #4D6EE3;
    margin-bottom: 15px;
}

.eventround-btn {
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    background-color: #FFDEDE;
    color: #F34B4B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.sp-accbox_eventedit_icon {
    width: 34px;
    height: 34px;
    background: #4D6EE31A;
    border: 1px solid #4D6EE31A !important;
    color: #4D6EE3;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

.content.content_style_view a {
    color: rgb(0, 0, 238);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: #485CF0 !important;
}

.editorClassName {
    height: 200px !important;
    resize: none !important;
    word-break: break-all;
    overflow-wrap: break-word;
    overflow: unset !important;
    white-space: normal !important;
}

.snackbar_success .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
    border: 1px solid #40BE61 !important;
    box-shadow: 0px 10px 24px 0px #40BE611A !important;
    background: #40BE61 !important;
    min-width: auto !important;
    border-radius: 5px;
}

.snackbar_failure .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
    box-shadow: 0px 10px 24px 0px #FD49541A !important;
    border: 1px solid #FD4954 !important;
    background: #FD4954 !important;
    min-width: auto !important;
    border-radius: 5px;
}

.css-l7mr9-MuiPaper-root-MuiSnackbarContent-root {
    min-width: auto !important;
}

.create_program_style .extra_pad_drop .field-value-holder .divide-gray-100.rounded.shadow {
    height: auto;
}

.height_fixed_program .extra_pad_drop .field-value-holder .divide-gray-100.rounded.shadow {
    height: 12pc;
}

.snack_total .icon-List_Present .path1:before {
    content: "\e90d";
    color: #fafbfc;
}

.snack_total .icon-List_Present .path2:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(64 190 97);
}

.snack_total .icon-List_Present {
    font-size: 21px;
}


.snack_total .icon-List_Absent .path1:before {
    content: "\e913";
    color: rgb(253 73 84);
}

.snack_total .icon-List_Absent .path2:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(253 73 84);
}

.snack_total .icon-List_Absent .path3:before {
    content: "\e915";
    margin-left: -1em;
    color: #fafbfc;
}

.snack_total .icon-List_Absent .path4:before {
    content: "\e916";
    margin-left: -1em;
    color: #fafbfc;
}

.snack_total .icon-List_Absent .path5:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(253 73 84);
}

.snack_total .icon-List_Absent .path6:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(253 73 84);
}

.snack_total .icon-List_Absent {
    font-size: 21px;
}

.leave_app_img_style img {
    width: 29px;
    height: 29px;
}

.leave_app_logo {
    width: 29px !important;
    height: 29px !important;
}

.btn-search-bar.flexc .icon-Close {
    position: absolute;
    right: 5px;
    cursor: pointer;
    color: #0b0b0b;
}

.nodata_text_style {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #000000;
}



/* 06.12.2025 */

.date_iconpos_rel {
    position: relative;
}

.date_iconpos_rel i {
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 15px;
    color: #4D505E !important;
}

.create_post_imagessize img {
    width: 38px !important;
    height: 38px !important;
}


/* 09-12-2024 */
.im_sm_siz .icon-List_Leave .path2:before {
    color: #000000;
}

.im_sm_siz .icon-List_Leave .path1:before {
    color: #000000;
}

.create_postbox_div .icon-List_Leave .path2:before {
    color: #000000;
}

.create_postbox_div .icon-List_Leave .path1:before {
    color: #000000;
}